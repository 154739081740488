import API from '../../util/api';

import {
    LOAD_EXCEL_START,
    LOAD_EXCEL_FINISH,
    UPDATE_EXCEL_START,
    UPDATE_EXCEL_FINISH,
    SET_INDEX,
    INC_INDEX,
    DEC_INDEX,
    EDIT_EXCEL,
    TOGGLE_EXCEL_ACTIVE,
    TOGGLE_ACTIVE_FUSHI,
    TOGGLE_ACTIVE_JANI,
    TOGGLE_ACTIVE_KIRI,
    TOGGLE_ACTIVE_SECRET
} from './types';


export const loadExcel = () => dispatch => {
    return new Promise(function(resolve,reject){
        dispatch(loadexcelstart);
        API.fetch({
            method:'GET',
            endpoint:'/excel/list'
        }).then(result=>{
            dispatch(loadexcelfinish(result.data))
            resolve(result.data)
        }).catch(error=>{
            dispatch(loadexcelfinish([]));
            reject('error')
        })

    })
}

export const toggleActiveJani =(e) => dispatch => {
    console.log(e)
    API.fetch({
        method:"POST",
        endpoint:'/excel/toggleactivejani',
        data:{excel:e}
    }).then(result=>{
        console.log('FROM TOGGLE',result.data)
        dispatch(toggleActiveJaniSuccess(result.data))
    }).catch(error=>{
        console.log('TOGGLE JANI ERROR',error)
    })
}

export const toggleActiveJaniSuccess = (e) => {
    console.log('success',e)
    return  {
        type: TOGGLE_ACTIVE_JANI,
        payload:e
    }
}

export const toggleActiveFushi =(e) => dispatch => {
    console.log(e)
    API.fetch({
        method:"POST",
        endpoint:'/excel/toggleactivefushi',
        data:{excel:e}
    }).then(result=>{
        console.log('FROM TOGGLE',result.data)
        dispatch(toggleActiveFushiSuccess(result.data))
    }).catch(error=>{
        console.log('TOGGLE FUSHI ERROR',error)
    })
}

export const toggleActiveFushiSuccess = (e) => {
    console.log('success',e)
    return  {
        type: TOGGLE_ACTIVE_FUSHI,
        payload:e
    }
}

export const toggleActiveKiri =(e) => dispatch => {
    console.log('NEW KIRI TOGGLE')
    console.log(e)
    API.fetch({
        method:"POST",
        endpoint:'/excel/toggleactivekiri',
        data:{excel:e}
    }).then(result=>{
        console.log('FROM TOGGLE',result.data)
        dispatch(toggleActiveKiriSuccess(result.data))
    }).catch(error=>{
        console.log('TOGGLE FUSHI ERROR',error)
    })
}

export const toggleActiveKiriSuccess = (e) => {
    console.log('success',e)
    return  {
        type: TOGGLE_ACTIVE_KIRI,
        payload:e
    }
}
export const toggleActiveSecret =(e) => dispatch => {
    console.log(e)
    API.fetch({
        method:"POST",
        endpoint:'/excel/toggleactivesecret',
        data:{excel:e}
    }).then(result=>{
        console.log('FROM TOGGLE',result.data)
        dispatch(toggleActiveSecretSuccess(result.data))
    }).catch(error=>{
        console.log('TOGGLE FUSHI ERROR',error)
    })
}

export const toggleActiveSecretSuccess = (e) => {
    console.log('success',e)
    return  {
        type: TOGGLE_ACTIVE_SECRET,
        payload:e
    }
}


const loadexcelstart = () => {
    return {
        type:LOAD_EXCEL_START
    }
}

const loadexcelfinish = (data) => {
    return {
        type:LOAD_EXCEL_FINISH,
        payload:data
    }
}

export const setindex = (data) => {
    return {
        type:SET_INDEX,
        payload:data
    }
}

export const incindex = () =>{
    return {
        type: INC_INDEX
    }
}

export const decindex = () => {
    return {
        type: DEC_INDEX
    }
}

export const editexcel = (data) => {
    console.log('EDIT EXCEL!!!!',data)
    return {
        type: EDIT_EXCEL,
        payload:data
    }
}

