import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/buttons.css';
import logo from '../assets/logo_green.png';
import jani from '../assets/jani.jpg';
import {
    Heading,
    Text,
    Box,
    Flex
} from "@chakra-ui/react";
import { baseUrl } from '../config';

import { Button, ButtonGroup } from '@chakra-ui/react'
import { slideForward } from '../redux/actions/navactions';
import { useNavigate, useParams } from 'react-router-dom';
import { setCurrency } from '../redux/actions/onboardactions';
import {logout} from '../redux/actions/authActions';

const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {

    }

    render() {
        return (
                    <div className="contentBox">
                        <img src={logo} style={{marginBottom:50}}/>
                        <div style={{marginBottom:10}}>
                        <div>currency:</div>
                        <select
                            value={this.props.onboard.currency}
                            onChange={(e)=>{
                                this.props.setCurrency(e.target.value)
                            }}
                        >
                            <option value="USD">USD</option>
                            <option value="BAHT">BAHT</option>
                        </select>
                        </div>
                        <Box 
                        boxShadow='md' p='6' rounded='md' bg='#44b388'
                        as="button"
                        onClick={()=>{
                            this.props.slideForward();
                        }}
                        >
                            <div style={{color:'white'}}>Tap to begin</div>
                        </Box>
                        <Box
                        style={{
                            position:'fixed',
                            bottom:10,
                            right:10
                        }}
                        boxShadow='md' p='6' rounded='md' bg='white'
                        as="button"
                        onClick={()=>{
                            this.props.navigate('/admin')
                        }}
                        >
                            Admin
                        </Box>
                        <Box
                        style={{
                            position:'fixed',
                            bottom:10,
                            left:10
                        }}
                        boxShadow='md' p='6' rounded='md' bg='white'
                        as="button"
                        onClick={()=>{
                            this.props.logout();
                        }}
                        >
                            logout
                        </Box>

                        {/* <select style={{marginTop:15}}>
                            <option>Soneva Fushi</option>
                            <option>Soneva Jani</option>
                        </select> */}
                    </div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default withNavigation(connect(mapStateToProps,{
    slideForward,setCurrency,logout
  })(Home))