import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
    setTreatments,
    resetOnboarding
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/order.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from '../util/api';
import * as FormData from 'form-data';
import Mailgun from 'mailgun.js';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { isThisHour } from 'date-fns';
import { baseUrl, imageUrl } from '../config';
import { useNavigate, useParams } from 'react-router-dom';
import { setSlideIndex } from '../redux/actions/navactions';
import { SquareOutline } from 'react-ionicons';
import { CheckboxOutline} from 'react-ionicons';
import { loadsendto } from '../redux/actions/adminactions';
import { theme } from '@chakra-ui/react';

const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 

//const Mailgun = require('mailgun.js');
// const mailgun = new Mailgun(formData);
// const mg = mailgun.client({
// 	username: 'api',
// 	key: 'cfaf178d9d6d90aacc89b44bbf5a9b12-1c7e8847-1106c25d',
// });
const apiKey = 'cfaf178d9d6d90aacc89b44bbf5a9b12-1c7e8847-1106c25d';
const domain = 'jasonseck.com';
const mailgun = new Mailgun(FormData);
const mg = mailgun.client({username: 'api', key: apiKey});
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width:'600px',
      maxWidth:'50%',
      height:'600px',
      maxHeight:'50%',
      boxShadow: '-5px -5px 15px rgba(255,255,255,0.5),6px 6px 15px rgba(70,70,70,0.12)'

    },
  };
  
let subtitle;
let _subtitle;

class Symptoms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            treatments:[],
            loading:true,
            modal:false,
            t:{},
            ordernotes:'',
            uft:[],
            emailsending:false,
            sendmeacopy:true
        }
    }
    componentDidMount() {
        console.log("hellow what",this.props.admin)
        this.props.loadsendto();
        API.fetch({
            method:'GET',
            endpoint:'/excel/list',
        }).then(res=>{
            console.log('THE TREATMENT MASTER',res.data)
            this.setState({
                treatments:res.data.filter(e=>e.activejani == true),
                uft:res.data,
                loading:false
            })
        }).catch(error=>{
            alert('error loading')
            console.log(error)
        })
    }
    updatetreatment = (t,d) => {
        var newt = [...this.state.treatments];

        var i = newt.findIndex(e=>e._id == t._id);
        newt[i].s = !newt[i].s;
        newt[i].selectedDuration = d;
        this.setState({
            treatments:newt
        })
    }
    closemodal = () => {
        this.setState({
            modal:false
        })
    }
    selectmodal = (d) => {
        this.updatetreatment(this.state.t,d);
        this.setState({
            modal:false
        })
    }
    unselect = (t) => {
        var newt = [...this.state.treatments];
        var i = newt.findIndex(e=>e._id == t._id);
        newt[i].s = false;
        newt[i].selectedDuration = '';
        this.setState({
            treatments:newt,
            modal:false
        })

    }
    triggerlist = (symptoms) => {
        return symptoms.filter(e=>e.s === true).map(t=>{
            return t.t;
        })
    }
    render() {
        const {symptoms} = this.props.onboard;
        const {treatments} = this.state;
        var chosen = this.triggerlist(symptoms);
        console.log('ADMIN STATE IS',this.state)
        return this.state.loading ? null : (
                <div className="welcomeWrap"
                >
                    <div className="contentBox">
                        <div className="screenDescription">Select treatment for further information:</div>
                        <div className="orderwrap">
                            <div className="symptomtitle">TCM treatments</div>
                                <div className="treatmentlist">
                                {treatments.filter(e=>e.category == "tcm").map(e=>{
                                    let intersection = e.triggers.filter(x=>chosen.includes(x))

                                    if(intersection.length > 0) {
                                        return (
                                            <div className="orderitem">
                                                   
                                            <div className="orderbutton" style={{background:e.s ? '#448888' : '#44b388',color:'white'}}
                                                onClick={()=>{
                                                    var newmass = this.state.treatments;
                                                    var i = newmass.findIndex(w=>{
                                                       return w._id == e._id
                                                    });
                                                     this.setState({
                                                        t:newmass[i],
                                                        modal:true
                                                     })
                                                    //  this.setState({
                                                    //     treatments:newmass
                                                    //  })
                                                    // this.setState({
                                                    //     treatments : {...this.state.treatments,tcm:newmass}
                                                    // })
                                                    console.log(newmass[i])
                                                }}
    
                                                >
                                        <div className="obtextwrap">
                                            <div className="obtitle">{e.title}</div>
                                            <div className="obdescription">{e.description.substr(0,50) +  "..."}</div>
                                        </div>
                                        </div>
    
    
                                        </div>
                                            )

                                    }
                                    })}
                                    </div>

                        </div>
                        
                        <div className="orderwrap">
                            <div className="symptomtitle">Naturopathic / Regenerative treatments</div>
                            <div className="treatmentlist">
                                {treatments.filter(w=>w.category == "nrmed").map(e=>{
                                    let intersection = e.triggers.filter(x=>chosen.includes(x))

                                    if(intersection.length > 0) {
                                        return (
                                            <div className="orderitem">
                                                   
                                            <div className="orderbutton" style={{background:e.s ? '#448888' : '#44b388',color:'white'}}
                                                onClick={()=>{
                                                    var newmass = this.state.treatments;
                                                    var i = newmass.findIndex(w=>{
                                                       return w._id == e._id
                                                    });
                                                     this.setState({
                                                        t:newmass[i],
                                                        modal:true
                                                     })
                                                    //  this.setState({
                                                    //     treatments:newmass
                                                    //  })
                                                    // this.setState({
                                                    //     treatments : {...this.state.treatments,tcm:newmass}
                                                    // })
                                                    console.log(newmass[i])
                                                }}
    
                                                >
                                        <div className="obtextwrap">
                                            <div className="obtitle">{e.title}</div>
                                            <div className="obdescription">{e.description.substr(0,50) + "..."}</div>
                                        </div>
                                                {/* {e.s &&
                                                <div className="obbottom">{e.selectedDuration?.duration} {e.selectedDuration.price ? '/ $' + e.selectedDuration.price : null}</div>
    
                                                } */}
                                        </div>
    
    
                                        </div>
                                            )

                                    }
                                    })}
                                    </div>

                        </div>
                        <div className="orderwrap">
                            <div className="symptomtitle">Ayurveda treatments</div>
                            <div className="treatmentlist">
                                {treatments.filter(w=>w.category == "ayurveda").map(e=>{
                                    let intersection = e.triggers.filter(x=>chosen.includes(x))

                                    if(intersection.length > 0) {
                                        return (
                                            <div className="orderitem">
                                                   
                                            <div className="orderbutton" style={{background:e.s ? '#448888' : '#44b388',color:'white'}}
                                                onClick={()=>{
                                                    var newmass = this.state.treatments;
                                                    var i = newmass.findIndex(w=>{
                                                       return w._id == e._id
                                                    });
                                                     this.setState({
                                                        t:newmass[i],
                                                        modal:true
                                                     })
                                                    //  this.setState({
                                                    //     treatments:newmass
                                                    //  })
                                                    // this.setState({
                                                    //     treatments : {...this.state.treatments,tcm:newmass}
                                                    // })
                                                    console.log(newmass[i])
                                                }}
    
                                                >
                                        <div className="obtextwrap">
                                            <div className="obtitle">{e.title}</div>
                                            <div className="obdescription">{e.description.substr(0,50) + "..."}</div>
                                        </div>
                                                {/* {e.s &&
                                                <div className="obbottom">{e.selectedDuration?.duration} {e.selectedDuration.price ? '/ $' + e.selectedDuration.price : null}</div>
    
                                                } */}
                                        </div>
    
    
                                        </div>
                                            )

                                    }
                                    })}
                                    </div>

                        </div>
                        <div className="orderwrap">
                            <div className="symptomtitle">Wellness Specialists</div>
                            <div className="treatmentlist">
                                {treatments.filter(w=>w.category == "specialist").map(e=>{
                                    let intersection = e.triggers.filter(x=>chosen.includes(x))

                                    if(intersection.length > 0) {
                                        return (
                                            <div className="orderitem">
                                                   
                                            <div className="orderbutton" style={{background:e.s ? '#448888' : '#44b388',color:'white'}}
                                                onClick={()=>{
                                                    var newmass = this.state.treatments;
                                                    var i = newmass.findIndex(w=>{
                                                       return w._id == e._id
                                                    });
                                                     this.setState({
                                                        t:newmass[i],
                                                        modal:true
                                                     })
                                                    //  this.setState({
                                                    //     treatments:newmass
                                                    //  })
                                                    // this.setState({
                                                    //     treatments : {...this.state.treatments,tcm:newmass}
                                                    // })
                                                    console.log(newmass[i])
                                                }}
    
                                                >
                                        <div className="obtextwrap">
                                            <div className="obtitle">{e.title}</div>
                                            <div className="obdescription">{e.description.substr(0,50) + "..."}</div>
                                        </div>
                                                {/* {e.s &&
                                                <div className="obbottom">{e.selectedDuration?.duration} {e.selectedDuration.price ? '/ $' + e.selectedDuration.price : null}</div>
    
                                                } */}
                                        </div>
    
    
                                        </div>
                                            )

                                    }
                                    })}
                                    </div>

                        </div>
                        <div className="orderwrap">
                            <div className="symptomtitle">Massage treatments</div>
                            <div className="treatmentlist">
                                {treatments.filter(w=>w.category == "massage").map(e=>{
                                    let intersection = e.triggers.filter(x=>chosen.includes(x))

                                    if(intersection.length > 0) {
                                        return (
                                            <div className="orderitem">
                                                   
                                            <div className="orderbutton" style={{background:e.s ? '#448888' : '#44b388',color:'white'}}
                                                onClick={()=>{
                                                    var newmass = this.state.treatments;
                                                    var i = newmass.findIndex(w=>{
                                                       return w._id == e._id
                                                    });
                                                     this.setState({
                                                        t:newmass[i],
                                                        modal:true
                                                     })
                                                    //  this.setState({
                                                    //     treatments:newmass
                                                    //  })
                                                    // this.setState({
                                                    //     treatments : {...this.state.treatments,tcm:newmass}
                                                    // })
                                                    console.log(newmass[i])
                                                }}
    
                                                >
                                        <div className="obtextwrap">
                                            <div className="obtitle">{e.title}</div>
                                            <div className="obdescription">{e.description.substr(0,50) + "..."}</div>
                                        </div>
                                                {/* {e.s &&
                                                <div className="obbottom">{e.selectedDuration?.duration} {e.selectedDuration.price ? '/ $' + e.selectedDuration.price : null}</div>
    
                                                } */}
                                        </div>
    
    
                                        </div>
                                            )

                                    }
                                    })}
                                    </div>

                        </div>
                        <div className="orderwrap">
                            <div className="symptomtitle">Movement treatments</div>
                            <div className="treatmentlist">
                                {treatments.filter(w=>w.category == "movement").map(e=>{
                                    let intersection = e.triggers.filter(x=>chosen.includes(x))

                                    if(intersection.length > 0) {
                                        return (
                                            <div className="orderitem">
                                                   
                                            <div className="orderbutton" style={{background:e.s ? '#448888' : '#44b388',color:'white'}}
                                                onClick={()=>{
                                                    var newmass = this.state.treatments;
                                                    var i = newmass.findIndex(w=>{
                                                       return w._id == e._id
                                                    });
                                                     this.setState({
                                                        t:newmass[i],
                                                        modal:true
                                                     })
                                                    //  this.setState({
                                                    //     treatments:newmass
                                                    //  })
                                                    // this.setState({
                                                    //     treatments : {...this.state.treatments,tcm:newmass}
                                                    // })
                                                    console.log(newmass[i])
                                                }}
    
                                                >
                                        <div className="obtextwrap">
                                            <div className="obtitle">{e.title}</div>
                                            <div className="obdescription">{e.description.substr(0,50) + "..."}</div>
                                        </div>
                                                {/* {e.s &&
                                                <div className="obbottom">{e.selectedDuration?.duration} {e.selectedDuration.price ? '/ $' + e.selectedDuration.price : null}</div>
    
                                                } */}
                                        </div>
    
    
                                        </div>
                                            )

                                    }
                                    })}
                                    </div>

                        </div>
                        <div className="orderwrap">
                            <div className="symptomtitle">Beauty treatments</div>
                            <div className="treatmentlist">
                                {treatments.filter(w=>w.category == "beauty").map(e=>{
                                    let intersection = e.triggers.filter(x=>chosen.includes(x))

                                    if(intersection.length > 0) {
                                        return (
                                            <div className="orderitem">
                                                   
                                            <div className="orderbutton" style={{background:e.s ? '#448888' : '#44b388',color:'white'}}
                                                onClick={()=>{
                                                    var newmass = this.state.treatments;
                                                    var i = newmass.findIndex(w=>{
                                                       return w._id == e._id
                                                    });
                                                     this.setState({
                                                        t:newmass[i],
                                                        modal:true
                                                     })
                                                    //  this.setState({
                                                    //     treatments:newmass
                                                    //  })
                                                    // this.setState({
                                                    //     treatments : {...this.state.treatments,tcm:newmass}
                                                    // })
                                                    console.log(newmass[i])
                                                }}
    
                                                >
                                        <div className="obtextwrap">
                                            <div className="obtitle">{e.title}</div>
                                            <div className="obdescription">{e.description.substr(0,50) + "..."}</div>
                                        </div>
                                                {/* {e.s &&
                                                <div className="obbottom">{e.selectedDuration?.duration} {e.selectedDuration.price ? '/ $' + e.selectedDuration.price : null}</div>
    
                                                } */}
                                        </div>
    
    
                                        </div>
                                            )

                                    }
                                    })}
                                    </div>

                        </div>
                        <div className="orderwrap">
                            <div className="symptomtitle">MD / Nurse treatments</div>
                            <div className="treatmentlist">
                                {treatments.filter(w=>w.category == "mdnurse").map(e=>{
                                    let intersection = e.triggers.filter(x=>chosen.includes(x))

                                    if(intersection.length > 0) {
                                        return (
                                            <div className="orderitem">
                                                   
                                            <div className="orderbutton" style={{background:e.s ? '#448888' : '#44b388',color:'white'}}
                                                onClick={()=>{
                                                    var newmass = this.state.treatments;
                                                    var i = newmass.findIndex(w=>{
                                                       return w._id == e._id
                                                    });
                                                     this.setState({
                                                        t:newmass[i],
                                                        modal:true
                                                     })
                                                    //  this.setState({
                                                    //     treatments:newmass
                                                    //  })
                                                    // this.setState({
                                                    //     treatments : {...this.state.treatments,tcm:newmass}
                                                    // })
                                                    console.log(newmass[i])
                                                }}
    
                                                >
                                        <div className="obtextwrap">
                                            <div className="obtitle">{e.title}</div>
                                            <div className="obdescription">{e.description.substr(0,50) + "..."}</div>
                                        </div>
                                                {/* {e.s &&
                                                <div className="obbottom">{e.selectedDuration?.duration} {e.selectedDuration.price ? '/ $' + e.selectedDuration.price : null}</div>
    
                                                } */}
                                        </div>
    
    
                                        </div>
                                            )

                                    }
                                    })}
                                    </div>

                        </div>
                        <div className="ordernoteswrap">
                            <div className="ordernotestitle">Notes</div>
                            <textarea className="ordernotestextarea"
                                value={this.state.ordernotes}
                                onChange={(e)=>{
                                    this.setState({
                                        ordernotes:e.target.value
                                    })
                                }}
                            />
                        </div>
                        <div className="orderssubmit">
                            <div className="orderbutton2"
                                onClick={()=>{
                                    var chosen = this.state.treatments.filter(e=>e.s == true);
                                    console.log('THE CHOSEON ONE',chosen)

                                    var o = this.props.onboard;
                                    var construct = {
                                        arrived : o.arrived,
                                        departing: o.departing,
                                        email:o.email,
                                        feelingscore:o.feelingscore,
                                        name:o.name,
                                        symptoms:o.symptoms,
                                        chosen:chosen,
                                        notes:this.state.ordernotes

                                    }
                                    API.fetch({
                                        method:'POST',
                                        endpoint:'/order/add',
                                        data:construct
                                    })

                                    var myhtml = '<div style="color:#000001;"><h1>Treatment order for '+this.props.onboard.name+'</h1><br/><br/> ';
                                    myhtml += '<h2>Feeling Score: ' + this.props.onboard.feelingscore + '</h2><br/>'
                                    myhtml += '<h2>Recomended Treatments: </h2><br/>'
                                    this.state.treatments.filter(t=>t.s == true).map(e=>{
                                        myhtml += '<br/><h2>' + e.title + '</h2><br/>'
                                        myhtml += '<p>' + e.description + '</p>'
                                        myhtml += '<p>Selected duration and price: ' + e.selectedDuration.duration + ' / $' + e.selectedDuration.price + '</p>'
                                        myhtml += '<hr/>'
                                    })                  
                                    myhtml += '<br/><br/>'
                                    myhtml += '<b>Barefoot Guardian(s)</b>: '
                                    myhtml += this.props.onboard?.bgs?.map(b=>{return '<br/>' + b.name})
                                    myhtml += '<br/><br/>'

                                    myhtml += '<b>notes:</b> ' + '<p>' + this.state.ordernotes + '</p></div>'
                                    var wa = 'Treatment order for _' + this.props.onboard.name + '_';
                                    wa += '\n' + 'Villa: ' + this.props.onboard.villa
                                    wa += '\n\n';
                                    
                                    this.state.treatments.filter(t=>t.s == true).map(e=>{

                                        wa += '*' + e.title + '*: '+ e.selectedDuration.duration + '\n\n';
                                    })                  


                                    API.fetch({
                                        method:'POST',
                                        endpoint:'/twilio',
                                        data:{message:wa}
                                    }).then(res=>{console.log(res)}).catch(error=>{
                                        console.log(error)
                                    })
                                    console.log('HTML for EMAIL;')
                                    console.log(myhtml)
                                    console.log('sending to',[...this.props.admin.sendto,this.state.sendmeacopy ? this.props.onboard.email : null,...this.props.onboard?.bgs?.map(b=>{return b.email.email})])
                                                        this.setState({emailsending:true})
                                        mg.messages.create('jasonseck.com', {
                                        from: "jason@jasonseck.com",
                                        to: [...this.props.admin.sendto,this.state.sendmeacopy ? this.props.onboard.email : null,...this.props.onboard?.bgs?.map(b=>{return b.email})],
                                        subject: "Soneva Welcome Treatment email",
                                        text: "Soneva Welcome Treatment email",
                                        html: myhtml
                                      })
                                      .then(msg => 
					{
                        this.setState({emailsending:false})
                        this.setState({
                            finish:true
                        })
                    }) // logs response data
                                      .catch(err => console.error(err)); // logs any error
                                    
                                }}
                            >
                                {this.state.emailsending ? (
                                <div className="loader"></div>

                                ) :
                                (
                                    <div>send</div>
                                )}
                            </div>
                            <div className="sendmeacopywrap">
                                    {this.state.sendmeacopy ? (
                                    <CheckboxOutline
                                        color={'#303030'}
                                        onClick={()=>{
                                            this.setState({
                                                sendmeacopy:!this.state.sendmeacopy
                                            })
                                        }}
                                        style={{
                                            cursor:'pointer',
                                            width:40,
                                            height:40
                                        }}
                                    />
                                    ) : (
                                        <SquareOutline
                                        color={"#303030"}
                                        onClick={()=>{
                                            this.setState({
                                                sendmeacopy:!this.state.sendmeacopy
                                            })
                                        }}
                                        style={{
                                            cursor:'pointer',
                                            width:40,
                                            height:40
                                        }}

                                        />
                                    )}
                                    <div style={{marginLeft:10}}>Send me a copy</div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={this.state.finish}
                        // onRequestClose={()=>{
                        //     this.setState({finish:false})
                        // }}
                        className="modalbox"
                        
                        contentLabel="Thank You"
                        >
                            <div className="modalinterior">
                                <div className="modaltop" style={{textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                    <h2 style={{fontSize:20,fontWeight:'bold'}}>{this.props.admin.title}</h2>
                                    <p>{this.props.admin.message}</p>
                                        <br/><br/>
                                    <p style={{fontSize:24,fontWeight:'bold'}}>{this.props.admin.salutation}</p>
                                </div>
                                <div className="modalbottom">
                                    <div className="modalbuttons">
                                        <div className="mb"
                                            onClick={()=>{
                                                this.setState({
                                                    finish:false
                                                })
                                                this.props.resetOnboarding();
                                                this.props.setSlideIndex(0);
        
                                            }}
                                        >Finish</div>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                    <Modal
                        isOpen={this.state.modal}
                        onRequestClose={()=>{
                            this.setState({modal:false})
                        }}
                        className="modalbox"
                        contentLabel={this.state.t.title}
                      >
                        <div className="modalinterior">
                            <div className="modaltop">
                                {this.state.t.image ? (
                                    <div style={{
                                        background:'url("'+imageUrl + this.state.t.image +'")',
                                        backgroundSize:'cover',
                                        backgroundPosition:'center',
                                        width:'100%',
                                        paddingTop:'30%',
                                        position:'relative',
                                        marginBottom:30
                                    }}>
                                       <div
                                        style={{
                                            position:'absolute',
                                            top:0,
                                            left:0,
                                            right:0,
                                            bottom:0,
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            backgroundColor:'#dedede55'
                                        }}
                                       >
                                        <div className="" style={{fontSize:30,fontWeight:'bold'}} ref={(_subtitle) => (subtitle = _subtitle)}>{this.state.t.title}</div>
                                        </div>
                                        </div>

                                ) : (
                                    <h2 className="modaltitle" ref={(_subtitle) => (subtitle = _subtitle)}>{this.state.t.title}</h2>

                                )
                                }
                                {this.state.t.components && <div className="modaldesc">Components: {this.state.t.components}</div>}
                                {this.state.t.outcome && <div className="modaldesc">Outcome: {this.state.t.outcome}</div>}
                                {this.state.t.description && <div className="modaldesc">{this.state.t.description}</div>}
                                {this.state.t.video && <video src={imageUrl + this.state.t.video} style={{width:'100%',marginTop:10,marginBottom:10,marginTop:30}} controls/>}
                                {this.state.t.specialist?.image &&
                                    <div style={{width:'100%',textAlign:'center',marginTop:20}}>
                                    <div style={{
                                        background:'url("'+imageUrl + this.state.t.specialist.image +'")',
                                        backgroundSize:'cover',
                                        backgroundPosition:'center',
                                        width:100,
                                        height:100,
                                        borderRadius:50,
                                        marginTop:15,
                                        margin:'auto'
                                    }}>
                                        </div>
                                        </div>
                                }
                                {this.state.t.specialist &&
                                    <div className="modalSpecialist">
                                        <div style={{fontWeight:'bold',fontSize:20}}>Specialist:</div>
                                        {this.state.t.specialist.name}
                                    </div>
                                }

                            </div>
                            <div className="modalbottom">

                                <div className="modalbuttons">
                                <div className="modaltitle">Please select a duration:</div>
                                    <div className="modalselectrow">
                                        {this.state.t?.details?.map(d=>{
                                            return (
                                                <div className="durationbuttonwrap">
                                                <div className="durationbutton"
                                                onClick={()=>{
                                                    this.selectmodal(d)}
                                                }
                                                ><div>{d.duration} min.</div>
                                                {this.props.onboard.currency == "USD" ? (
                                                <div> {d.price ? '$' + d.price : null}</div>
                                                ) : (
                                                    <div>฿{d.pricebaht}</div>

                                                )}
                                                </div>
                                                </div>
        
                                            )
                                        })}

                                    </div>
                                    {this.state.t.s && <div className="mb" onClick={()=>{this.unselect(this.state.t)}}>Unselect this treament</div>}

                                <div className="mb"
                                    onClick={this.closemodal}
                                >Cancel</div>

                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard,
    admin:state.admin
});
  
  export default withNavigation(connect(mapStateToProps,{
    setTreatments,
    setSlideIndex,
    resetOnboarding,
    loadsendto
})(Symptoms))
