import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
    setFeelingScore
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/buttons.css';
import logo from '../assets/logo.png';
import jani from '../assets/jani.jpg';
import { Radio, RadioGroup, Stack, Box } from '@chakra-ui/react'


class HowAreYouFeeling extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption : 1
        }
    }
    componentDidMount() {

    }

    render() {
        const {onboard} = this.props;
        console.log(onboard.feelingscore)
        return (
                    <div className="contentBox">
                        <Box bg='#44b388' p={4} color='white' display='flex' alignItems='center' style={{flexDirection:'column',borderRadius:5,margin:10}} boxShadow="xl">
                        <div className="screenTitleBig">How are you Feeling?</div>
                        <div className="screenDescription">How are you feeling today on a scale of one to five, with one being feeling poorly, and five being feeling absolutely amazing.</div>
                        <RadioGroup
                            onChange={(e)=>{
                                this.props.setFeelingScore(e)
                            }}
                            value={onboard.feelingscore}
                            defaultValue={onboard.feelingscore}
                            mt={20}
                            >
                                <Stack direction='row'>
                                    <Radio size='lg' value='1'>1</Radio>
                                    <Radio size='lg' value='2'>2</Radio>
                                    <Radio size='lg' value='3'>3</Radio>
                                    <Radio size='lg' value='4'>4</Radio>
                                    <Radio size='lg' value='5'>5</Radio>
                                </Stack>

                            </RadioGroup>
                            </Box>
                    </div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default connect(mapStateToProps,{
    setFeelingScore
})(HowAreYouFeeling);