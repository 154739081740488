import React from 'react';
import API from '../util/api';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 

class Orders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orders:[],
            loading:true
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/order/list/',
        }).then(res=>{
            this.setState({
                orders:res.data,
                loading:false
            })
        }).catch(error=>{
            console.log(error)
            console.log('error loading orders')
        })
    }
    render() {
        return this.state.loading ? (<div>loading</div>):(
            <div style={{padding:25}}>
                <table style={{width:'100%'}}>
                    <thead>
                        <th>name</th>
                        <th>email</th>
                        <th>feeling score</th>
                        <th>arrival date</th>
                        <th>index</th>
                    </thead>
                    <tbody>
                {this.state.orders.map((o,index)=>{
                    return (
                        <tr onClick={()=>{
                            console.log(o)
                            this.props.navigate('/admin/order/view/' + o._id )
                        }}>
                            <td style={{border:'1px solid black',padding:8}}>{o.name}</td>
                            <td style={{border:'1px solid black',padding:8}}>{o.email}</td>
                            <td style={{border:'1px solid black',padding:8}}>{o.feelingscore}</td>
                            <td style={{border:'1px solid black',padding:8}}>{moment(o.arrived).format("MM-DD-YYYY")}</td>
                            <td style={{border:'1px solid black',padding:8}}>{index}</td>
                        </tr>
                    )
                })}
                    </tbody>
                    </table>


            </div>
        )
    }

}
export default withNavigation(Orders)