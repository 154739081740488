export const UPDATE_DATA = "UPDATE_DATA";
export const SET_FEELING_SCORE = "SET_FEELING_SCORE";
export const SET_NAME = "SET_NAME";
export const SET_ARRIVED = "SET_ARRIVED";
export const SET_DEPARTING = "SET_DEPARTING";
export const SET_SYMPTOMS = "SET_SYMPTOMS";
export const SET_WELLNESS = "SET_WELLNESS";
export const SET_TREATMENTS = "SET_TREATMENTS";
export const RESET_ONBOARDING = "RESET_ONBOARDING";
export const SET_BG = "SET_BG";
export const SET_VILLA = "SET_VILLA";


export const SET_HEADER_TITLE = "SET_HEADER_TITLE";
export const SLIDE_FORWARD = "SLIDE_FORWARD";
export const SLIDE_BACK = "SLIDE_BACK";
export const SET_SLIDE_INDEX = "SET_SLIDE_INDEX";

export const LOAD_EXCEL_START = "LOAD_EXCEL_START";
export const LOAD_EXCEL_FINISH = "LOAD_EXECL_FINISH";
export const UPDATE_EXCEL_START = "UPDATE_EXCEL_START";
export const UPDATE_EXCEL_FINISH = "UPDATE_EXCEL_FINISH";

export const EDIT_EXCEL = "EDIT_EXCEL";
export const TOGGLE_EXCEL_ACTIVE = "TOGGLE_EXCEL_ACTIVE";
export const TOGGLE_ACTIVE_JANI = "TOGGLE_ACTIVE_JANI";
export const TOGGLE_ACTIVE_FUSHI = "TOGGLE_ACTIVE_FUSHI";
export const TOGGLE_ACTIVE_KIRI = "TOGGLE_ACTIVE_KIRI";
export const TOGGLE_ACTIVE_SECRET = "TOGGLE_ACTIVE_SECRET";



export const SET_INDEX = "SET_INDEX";
export const DEC_INDEX = "DEC_INDEX";
export const INC_INDEX = "INC_INDEX";

export const UPDATE_SENDTO = "UPDATE_SENDTO";
export const LOAD_SENDTO_START = "LOAD_SENDTO_START";
export const LOAD_SENDTO_SUCCESS = "LOAD_SENDTO_SUCCESS";
export const ADD_SENDTO = "ADD_SENDTO";
export const REMOVE_SENDTO = "REMOVE_SENDTO";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_SALUTATION = "UPDATE_SALUTATION";



export const SET_EMAIL = "SET_EMAIL";

export const SET_CURRENCY = "SET_CURRENCY";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USER_NOT_LOADING = "SET_USER_NOT_LOADING";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
