import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
    setWellness
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/symptoms.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Wellness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    updatesym = (sym) => {
        var newsym = [...this.props.onboard.wellness];

        var i = newsym.findIndex(e=>e.q == sym.q);
        newsym[i].s = !newsym[i].s;
        this.props.setWellness(newsym)
    }
    render() {
        const {wellness} = this.props.onboard;
        return (
                <div className="welcomeWrap"
                >
                    <div className="contentBox">
                        <div className="screenTitleBig">What are your WELLNESS goals?</div>
                        <div className="screenDescription">Please click on any of the below that are true for you:</div>
                        <div className="symwrap">
                            {wellness.map(sym=>{
                                return (
                                    <div className="symbutton" style={{background:sym.s ? 'lightskyblue' : 'seashell'}}
                                        onClick={()=>{
                                            this.updatesym(sym)
                                        }}
                                    >{sym.q}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default connect(mapStateToProps,{
    setWellness
})(Wellness)