import React from 'react';
import {connect} from 'react-redux';
import { loadExcel,setindex, incindex, decindex, editexcel } from '../redux/actions/excelactions';
import API from '../util/api';
import _ from 'underscore';
import { useNavigate, useParams } from 'react-router-dom';
import { imageUrl, avail } from '../config';
const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 

const triggers = [
    'chronic',
    'tired',
    'relax',
    'jetlag',
    'pain',
    'optimize',
    'weight',
    'sleep',
    'tension',
    'fitness',
    'beauty',
    'sick'
]
class ExcelEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            r:null,
            loading:true,
            duration:30,
            price:120,
            pricebaht:3840,
            note:'',
            specialists:[]
        }
    }
    async componentDidMount() {
        try {
            API.fetch({
                method:'GET',
                endpoint:'/specialist/list'
            }).then(res=>{
                this.setState({
                    specialists:res.data
                })
            }).catch(error=>{
                console.log('error loading specialists')
            })
        //check if props are loaded yet, and it not, load them (came in from a link or refresh)\
            if(!this.props.excel.loaded) {
                await this.props.loadExcel();

            }
        //get the current one we're working on.
            console.log('here the record')
            console.log(this.props.excel.excel[this.props.excel.index])
            this.setState({r:this.props.excel.excel[this.props.excel.index]})
            
        } catch(error) {
            console.log('edit error')
        }
        var uniq = []
        this.props.excel.excel.map(c=>{
            c.triggers.map(t=>{
                if(!uniq.includes(t)) {
                    uniq.push(t)
                }
            })
        })
        console.log(uniq)


    }
    componentDidUpdate(prevprops) {
        if(this.props.excel.index != prevprops.excel.index) {
            this.setState({r:this.props.excel.excel[this.props.excel.index]})
        }
    }
    render() {
        console.log(this.state)
        const {index} = this.props.excel
        var r = this.props.excel.excel[index];
        return (!this.props.excel.loaded) ? null : (
            <div className="mainwindow">
                    <div className="theverytop">
                    <div className="button"
                        onClick={()=>{this.props.navigate(-1)}}
                    >back to list</div>
                    <div className="buttonrow">
                        <div className="button"
                            onClick={()=>{
                                if(index>0) {
                                    if(_.isEqual(r,this.state.r)) {
                                        this.props.decindex();

                                    } else {
                                        if(window.confirm("do you want to save changes") == true) {
                                            API.fetch({
                                                method:'POST',
                                                endpoint:'/excel/update',
                                                data:{excel:r}
                                            })
                                            this.props.loadExcel();
                                            this.props.decindex();
                                        } else {
                                            this.props.loadExcel();
                                            this.props.decindex();
                                        }
                                    }
                            }

                            }}
                        >prev</div>
                        <div className="button"
                            onClick={()=>{
                                if (index < this.props.excel.excel.length -1) {
                                    if(_.isEqual(r,this.state.r)) {
                                        this.props.incindex();

                                    } else {
                                        if(window.confirm("do you want to save changes") == true) {
                                            API.fetch({
                                                method:'POST',
                                                endpoint:'/excel/update',
                                                data:{excel:r}
                                            })
                                            this.props.loadExcel();
                                            this.props.incindex();
                                        } else {
                                            this.props.loadExcel();
                                            this.props.incindex();
                                        }
                                    }

                                }
                            }}
                            >next</div>
                            <div className="editcount">{index + 1} / {this.props.excel.excel.length}</div>
                        </div>
                    </div>
                <div className="editwrap">
                <div className="editcol">
                <div className="editform">
                <div className="edititem">
                    <div className="inputtitle">Title</div>
                    <input
                        className="editinput"
                        value={r.title}
                        onChange={(e)=>{
                            this.props.editexcel({
                                index:index,
                                field:'title',
                                value:e.target.value
                            })
                        }}
                        />
                </div>
                <div className="edititem">
                    <div className="inputtitle">Description</div>
                    <textarea
                        className="editinput textedit"
                        value={r.description}
                        onChange={(e)=>{
                            this.props.editexcel({
                                index:index,
                                field:'description',
                                value:e.target.value
                            })
                        }}
                        />
                </div>
                <div className="edititem">
                    <div className="inputtitle">Components</div>
                    <textarea
                        className="editinput textedit"
                        value={r.components}
                        onChange={(e)=>{
                            this.props.editexcel({
                                index:index,
                                field:'components',
                                value:e.target.value
                            })
                        }}
                        />
                </div>
                <div className="edititem">
                    <div className="inputtitle">Image</div>
                    {r.image &&
                    <img src={imageUrl + r.image} style={{width:200,marginTop:10,marginBottom:10}}/>
                    }
                    <input
                    style={{marginTop:10}}
                        type="file"
                        name="image"
                        onChange={(e) => {
                        console.log(e.target.files[0]);
                            const formData = new FormData();
                            formData.append('image',e.target.files[0])
                            API.fetch({
                                method:"POST",
                                endpoint:"/upload",
                                data:formData
                            }).then(res=>{
                                console.log('THE RES UPLOAD',res.data)
                                this.setState({
                                    image:res.data.filename
                                })
                                this.props.editexcel({
                                    index:index,
                                    field:'image',
                                    value:res.data.filename
                                })
    
                            }).catch(error=>{
                                console.log('ERROR OF UPLOAD',error)
                            })
                        }}
                    />
                </div>
                <div className="edititem">
                    <div className="inputtitle">Video</div>
                    {r.video &&
                    <video src={imageUrl + r.video} style={{width:200,marginTop:10,marginBottom:10}} controls/>
                    }

                    <input
                    style={{marginTop:10}}
                        type="file"
                        name="image"
                        onChange={(e) => {
                        console.log(e.target.files[0]);
                            const formData = new FormData();
                            formData.append('image',e.target.files[0])
                            API.fetch({
                                method:"POST",
                                endpoint:"/upload",
                                data:formData
                            }).then(res=>{
                                console.log('THE RES UPLOAD',res.data)
                                this.setState({
                                    image:res.data.filename
                                })
                                this.props.editexcel({
                                    index:index,
                                    field:'video',
                                    value:res.data.filename
                                })
    
                            }).catch(error=>{
                                console.log('ERROR OF UPLOAD',error)
                            })
                        }}
                    />
                </div>

                <div className="edititem">
                    <div className="inputtitle">Category</div>

                    <select value={r.category}
                        onChange={(e)=>{
                            this.props.editexcel({
                                index:index,
                                field:'category',
                                value:e.target.value
                            })
                        }}
                        className="editinput"
                    >
                        <option value="massage">massage</option>
                        <option value="movement">movement</option>
                        <option value="beauty">beauty</option>
                        <option value="tcm">tcm</option>
                        <option value="nrmed">nrmed</option>
                        <option value="ayurveda">ayurveda</option>
                        <option value="mdnurse">mdnurse</option>
                        <option value="specialist">specialist</option>

                    </select>

                </div>
                {r.category == "specialist" &&
                <div className="edititem">
                       <select value={JSON.stringify(r.specialist)}
                        onChange={(e)=>{
                            this.props.editexcel({
                                index:index,
                                field:'specialist',
                                value:JSON.parse(e.target.value)
                            })
                        }}
                        className="editinput"
                    >
                        {this.state.specialists.map(s=>{
                            return (
                                <option value={JSON.stringify(s)}>{s.name}</option>
                            )
                        })}
                        </select>
                </div>

                }
                </div>
                </div>
                <div className="editcol">
                <div className="editform">
                <div className="edititem">
                    <div className="inputtitle">Triggers</div>
                    <div className="subinputtitle">selected:</div>
                    <div className="availselectedwrap">
                        {r.triggers.map((e)=>{
                        return <div key={e} className="availselected button"
                            onClick={()=>{
                                //make a copy of array
                                var copy = r.triggers;

                                //find index
                                var i = copy.findIndex(a=>a==e)
                                copy.splice(i,1);
                                console.log(copy)
                                //update the props
                                this.props.editexcel({
                                    index:index,
                                    field:'triggers',
                                    value:copy
                                })
                            }}
                        >{e}</div>

                        })}
                    </div>
                    <div className="subinputtitle">not selected:</div>
                    <div className="availnotselectedwrap">
                        {triggers.filter(x=>!r.triggers.includes(x)).map(e=>{
                            return <div key={e} className="availnotselected button"
                            onClick={()=>{
                                //make a new array
                                var newarray = [...r.triggers,e]
                                //push to store
                                this.props.editexcel({
                                    index:index,
                                    field:'triggers',
                                    value:newarray
                                })
                            }}
                            >{e}</div>
                        })}
                    </div>
                </div>
                <div className="edititem">
                    <div className="inputtitle">Details</div>
                        <div className="detailsrow">
                    {r.details.map((d,i)=>{
                        return (
                            <div key={i} className="detailswrap">
                                <div className="detailstitle">Duration</div>
                                <input
                                    className="detailsinput" 
                                    value={r.details[i].duration}
                                    onChange={(e)=>{
                                        console.log('E TARGET',e.target.value)
                                        var newdetails = r.details;
                                        console.log(newdetails[i])
                                        newdetails[i].duration = e.target.value;
                                        this.props.editexcel({
                                            index:index,
                                            field:'details',
                                            value:newdetails
                                        })
                                    }}
                                />
                                <div className="detailstitle">Price $(USD)</div>
                                <input 
                                className="detailsinput"
                                    value={r.details[i].price}
                                    onChange={(e)=>{
                                        console.log('E TARGET',e.target.value)
                                        var newdetails = r.details;
                                        console.log(newdetails[i])
                                        newdetails[i].price = e.target.value;
                                        this.props.editexcel({
                                            index:index,
                                            field:'details',
                                            value:newdetails
                                        })
                                    }}
                                />
                                <div className="detailstitle">Price ฿(BAHT)</div>
                                <input 
                                className="detailsinput"
                                    value={r.details[i].pricebaht}
                                    onChange={(e)=>{
                                        console.log('E TARGET',e.target.value)
                                        var newdetails = r.details;
                                        console.log(newdetails[i])
                                        newdetails[i].pricebaht = e.target.value;
                                        this.props.editexcel({
                                            index:index,
                                            field:'details',
                                            value:newdetails
                                        })
                                    }}
                                />

                                <div className="detailstitle">Notes</div>
                                <input 
                                className="detailsinput"
                                    value={r.details[i].note}
                                    onChange={(e)=>{
                                        console.log('E TARGET',e.target.value)
                                        var newdetails = r.details;
                                        console.log(newdetails[i])
                                        newdetails[i].note = e.target.value;
                                        this.props.editexcel({
                                            index:index,
                                            field:'details',
                                            value:newdetails
                                        })
                                    }}
                                />

                                <div className="detaildelete"
                                    onClick={()=>{
                                        var newdetails = r.details;
                                        newdetails.splice(i,1)
                                        this.props.editexcel({
                                            index:index,
                                            field:'details',
                                            value:newdetails
                                        })
                                    }}
                                >x</div>
                            </div>
                        )
                    })}
                    </div>
                    <div className="adddetailwrap">
                        <div className="adddetailitem">
                            <div className="adddetaillabel">duration</div>
                            <input className="adddetailinput"
                                value={this.state.duration}
                                onChange={(e)=>{
                                    this.setState({
                                        duration:e.target.value
                                    })
                                }}
                            ></input>
                        </div>
                        <div className="adddetailitem">
                            <div className="adddetaillabel">price USD</div>
                            <input className="adddetailinput"
                                value={this.state.price}
                                onChange={(e)=>{
                                    this.setState({
                                        price:e.target.value
                                    })
                                }}
                            ></input>
                        </div>
                        <div className="adddetailitem">
                            <div className="adddetaillabel">price BAHT</div>
                            <input className="adddetailinput"
                                value={this.state.pricebaht}
                                onChange={(e)=>{
                                    this.setState({
                                        pricebaht:e.target.value
                                    })
                                }}
                            ></input>
                        </div>

                        <div className="adddetailitem">
                            <div className="adddetaillabel">notes</div>
                            <input className="adddetailinput"
                                value={this.state.note}
                                onChange={(e)=>{
                                    this.setState({
                                        note:e.target.value
                                    })
                                }}
                            ></input>
                        </div>

                    </div>
                    <div className="button"
                        onClick={()=>{
                            var newone = [
                                ...r.details,
                                {
                                    duration:this.state.duration,
                                    price:this.state.price,
                                    note:this.state.note
                                }
                            ]
                            this.props.editexcel({
                                index:index,
                                field:'details',
                                value:newone
                            })
                        }}
                    >add detail item</div>
                </div>

                <div className="edititem">
                    <div className="inputtitle">Availability</div>
                    <div className="subinputtitle">selected:</div>
                    <div className="availselectedwrap">
                        {r.availability.map((e)=>{
                        return <div key={e} className="availselected button"
                            onClick={()=>{
                                //make a copy of array
                                var copy = r.availability;

                                //find index
                                var i = copy.findIndex(a=>a==e)
                                copy.splice(i,1);
                                console.log(copy)
                                //update the props
                                this.props.editexcel({
                                    index:index,
                                    field:'availability',
                                    value:copy
                                })
                            }}
                        >{e}</div>

                        })}
                    </div>
                    <div className="subinputtitle">not selected:</div>
                    <div className="availnotselectedwrap">
                        {avail.filter(x=>!r.availability.includes(x)).map(e=>{
                            return <div key={e} className="availnotselected button"
                            onClick={()=>{
                                //make a new array
                                var newarray = [...r.availability,e]
                                //push to store
                                this.props.editexcel({
                                    index:index,
                                    field:'availability',
                                    value:newarray
                                })
                            }}
                            >{e}</div>
                        })}
                    </div>
                </div>
                <div className="edititem">
                    <div className="inputtitle">Issue?</div>
                        <div className="button" style={{backgroundColor:r.issue ?'red' : 'green'}}
                        
                        onClick={(e)=>{
                            this.props.editexcel({
                                index:index,
                                field:'issue',
                                value:!r.issue
                            })
                        }}

                        >issue</div>
                </div>
                </div>
                </div>

                </div>
                <div style={{fontSize:10,marginBottom:5}}>{r._id}</div>
                <div className="bggroup">
                        <div className="button" style={{backgroundColor:'red',marginTop:10}}
                            onClick={()=>{
                                if(window.confirm('Delete this treatment record?')) {
                                    console.log(this.state)
                                    API.fetch({
                                        method:"POST",
                                        endpoint:"/excel/delete",
                                        data: {
                                            id:this.state.r._id
                                        }
                                    }).then(res=>{
                                        this.props.navigate(-1)
                                    }).catch(error=>{
                                        window.alert('Error deleting this treatment')
                                    })
    
                                } else {
                                    return;
                                }

                            }}
                        >delete</div>
                    </div>
                <div className="bottom">

                        <div className="equality">
                    {_.isEqual(r,this.state.r) ? (
                        <div>
                        <div className="button"
                            onClick={async()=>{
                                try {
                                    console.log(r)
                                    var res = await API.fetch({
                                        method:'POST',
                                        endpoint:'/excel/update',
                                        data:{excel:r}
                                    })
                                    console.log('THE RES',res)
                                    this.setState({r:r})
                                } catch(error) {
                                    console.log(error)
                                }
                            }}
                        >update</div>
                        </div>
                    ) : (
                        <div style={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center'
                        }}>
                        <div style={{color:'red',marginRight:10}}>changes not updated!</div>
                        <div className="button"
                            onClick={async()=>{
                                try {
                                    console.log(r)
                                    var res = await API.fetch({
                                        method:'POST',
                                        endpoint:'/excel/update',
                                        data:{excel:r}
                                    })
                                    console.log('THE RES',res)
                                    this.setState({r:r})
                                } catch(error) {
                                    console.log(error)
                                }
                            }}
                        >update</div>
                        </div>
                    )}
                </div>
                </div>
             
            </div>
        )
    }
}

const mapStateToProps = state => ({
    excel:state.excel
})

export default withNavigation(connect(mapStateToProps,{loadExcel,incindex,decindex,setindex,editexcel})(ExcelEdit))