import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Routes, Route, BrowserRouter, Navigate, Link } from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import store from './redux/store';
import Home from './screens/home';
import HowAreYouFeeling from './screens/howareyoufeeling';
import BasicInfo from './screens/basicinfo';
import Symptoms from "./screens/symptoms";
import Wellness from './screens/wellness';
import './screens/css/screen.css';
import Footer from './screens/footer';
import Header from './screens/header';
import Order from './screens/order';
import OrderView from './admin/orderview';
import Treatments from './admin/treatments';
import EditTreatment from './admin/edittreatment';
import NewTreatment from './admin/newtreatment';
import Admin from './admin/admin';
import SwipeNav from './swipe';
import Excel from './admin/excel';
import ExcelEdit from './admin/exceledit';
import ExcelCreate from './admin/createexcel';
import Orders from './admin/orders';
import AdminButton from './admin/adminbutton';
import Setup from './admin/setup';
import BGs from './admin/bgs';
import BGEdit from './admin/bgedit';
import BGAdd from './admin/bgadd';
import CreateSpecialist from "./admin/createspecialist";
import Specialist from './admin/specialist';
import EditSpecialist from './admin/editspecialist';
import Login from './login';
import PrivateRoute from './authwrap';

const Wr = ({children}) => {
  return (
    <>
      {children}
      <AdminButton/>
    </>
  )
}

// function PrivateRoute({children}) {
//     return <SwipeNav/>
// }

function Thing() {
    return (
        <Routes>
            <Route path="/" element={
            <PrivateRoute>
                <SwipeNav/>
            </PrivateRoute>

            }/>
            <Route path="/admin" element={
            <PrivateRoute>
                <Admin/>
            </PrivateRoute>

            }/>
            <Route path="/admin/excel" element={
            <PrivateRoute>
                <Wr children={<Excel/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/orders" element={
            <PrivateRoute>
                <Wr children={<Orders/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/orders/view/:id" element={
            <PrivateRoute>
                <Wr children={<OrderView/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/excel/edit" element={
            <PrivateRoute>
                <Wr children={<ExcelEdit/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/excel/create" element={
            <PrivateRoute>
                <Wr children={<ExcelCreate/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/bgs" element={
            <PrivateRoute>
                <Wr children={<BGs/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/bgs/edit" element={
            <PrivateRoute>
                <Wr children={<BGEdit/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/bgs/add" element={
            <PrivateRoute>
                <Wr children={<BGAdd/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/setup" element={
            <PrivateRoute>
                <Wr children={<Setup/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/specialist" element={
            <PrivateRoute>
                <Wr children={<Specialist/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/specialist/create" element={
            <PrivateRoute>
                <Wr children={<CreateSpecialist/>}/>
            </PrivateRoute>

            }/>
            <Route path="/admin/specialist/edit" element={
            <PrivateRoute>
                <Wr children={<EditSpecialist/>}/>
            </PrivateRoute>

            }/>

            <Route path="/login" element={<Login/>} />
        </Routes>
    )
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <SwipeNav/>
  },


]);


export default function main() {
    return (
        <Provider store={store}>
            <BrowserRouter>
            {/* <nav>
        <Link to="/">Home</Link>
        <Link to="/login">Login</Link>
      </nav> */}
                <Thing/>
            </BrowserRouter>
        </Provider>
    )
}

