import React from 'react';
import { connect } from "react-redux";
import './css/header.css'
import { Text } from "@chakra-ui/react"

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="header"><div className="headerTitle">{this.props.nav.headerTitle}</div></div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard,
    nav:state.nav
});


export default connect(
    mapStateToProps,
    {}
)(Header)