import React from 'react';
import { connect } from "react-redux";
import './css/header.css'
import { Text, Progress } from "@chakra-ui/react"

const screens = [
    'Welcome',
    'Basic Info',
    'How are you Feeling?',
    'Health Needs',
    'Recommended Treatments',
    'Admin'

]
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="header">

                <div className="headerTitle">{screens[this.props.nav.slideIndex]}</div>
                <Progress colorScheme='green' size='sm' hasStripe value={this.props.nav.slideIndex / 4 * 100} sx={{width:'100%'}}/>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard,
    nav:state.nav
});


export default connect(
    mapStateToProps,
    {}
)(Header)