import React, {Component} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../util/api';
import './admin.css';
export const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 

  const categories = [
    "imsfr",
    "imsjr",
    "spasfr",
    "spasjr",
    "childrens"
]

class EditTreatment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            treatment:null,
            loading:true,
            att:'',
            dur:'',
            price:'',
            note:''
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/treatments/list/' + this.props.params.id
        }).then(res=>{
            this.setState({
                treatment:res.data,
                loading:false
            })
        }).catch(error=>{
            console.log('error fetching edit')
        })
    }
    update() {
        API.fetch({
            method:'POST',
            endpoint:'/treatments/update',
            data: {
                treatment:this.state.treatment
            }
        }).then(res=>{
            this.props.navigate('/admin/treatments/')

        }).catch(error=>{
            alert('failed')
        })
    }
    delete() {
        API.fetch({
            method:'POST',
            endpoint:'/treatments/delete',
            data: {
                treatment:this.state.treatment
            }
        }).then(res=>{
            this.props.navigate('/admin/treatments/')
        }).catch(error=>{
            alert('failed')
        })
    }
    render() {
        const {id} = this.props.params
        return  this.state.loading ? (
            <div>edit treatment - {id} - loading</div>
        ) : (
            <div className="tform">
                <div className="tgroup">
                    <div className="ttitle">main category</div>
                    <select 
                        value={this.state.treatment.category}
                        onChange={(e)=>{
                        this.setState({
                            treatment: {
                                ...this.state.treatment, 
                                category:e.target.value
                            }
                        })
                    }}>
                        {categories.map((c,i)=>{
                            return (
                                <option key={i} value={c}>{c}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="tgroup">
                    <div className="ttitle">Category Title</div>
                    <input className="tinput"
                        value={this.state.treatment.categorytitle}
                        onChange={(e)=>{
                            this.setState({
                                treatment: {
                                    ...this.state.treatment,
                                    categorytitle:e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className="tgroup">
                    <div className="ttitle">Sub Category</div>
                    <input className="tinput"
                        value={this.state.treatment.subcategory}
                        onChange={(e)=>{
                            this.setState({
                                treatment: {
                                    ...this.state.treatment,
                                    subcategory:e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className="tgroup">
                    <div className="ttitle">attributes</div>
                    <div className="tagroup">
                        {this.state.treatment.attributes.map((a,index)=>{
                            return (
                                <div key={index} className="tattribute">{a}<div className="taclose"
                                    onClick={()=>{
                                        var array = [...this.state.treatment.attributes]
                                        array.splice(index,1);
                                        this.setState({
                                            treatment : {
                                                ...this.state.treatment, attributes:array
                                            }
                                        })
                                    }}
                                >x</div></div>

                            )
                        })}
                    </div>
                        <div className="tanew">
                            <input
                                value={this.state.att}
                                onChange={(e)=>{
                                    this.setState({att:e.target.value})
                                }}
                            />
                            <button onClick={()=>{
                                if(this.state.att != '') {
                                    this.setState({
                                        treatment: {
                                            ...this.state.treatment,attributes : [...this.state.treatment.attributes, this.state.att]
                                        },
                                        att:''
                                    })
                                }

                            }}>add</button>
                        </div>
                </div>
                <div className="tgroup">
                    <div className="ttitle">Tite</div>
                    <input className="tinput"
                        value={this.state.treatment.title}
                        onChange={(e)=>{
                            this.setState({
                                treatment: {
                                    ...this.state.treatment,
                                    title:e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className="tgroup">
                    <div className="ttitle">duration</div>
                    <div className="tagroup">
                        {this.state.treatment.details.map((d,index)=>{
                            return (
                                <div className="tattribute">
                                    <div className="dlist">
                                        <div className="ditem">
                                            <div className="ditemtitle">Duration - {d.duration}</div>
                                            <div className="ditemvalue">Price - {d.price}</div>
                                            <div className="ditemnote">Note - {d.note}</div>
                                        </div>
                                    </div>
                                <div className="taclose"
                                    onClick={()=>{
                                        var array = [...this.state.treatment.details]
                                        array.splice(index,1);
                                        this.setState({
                                            treatment : {
                                                ...this.state.treatment, details:array
                                            }
                                        })
                                    }}
                                >x</div></div>

                            )
                        })}
                    </div>
                        <div className="tanew">
                            <div className="dinputlist">
                                <div className="dinputitem">
                                    <div className="dinputtitle">
                                        duration
                                    </div>
                                    <input
                                        value={this.state.dur}
                                        onChange={(e)=>{
                                        this.setState({dur:e.target.value})
                                        }}
                                    />
                                </div>
                                <div className="dinputitem">
                                    <div className="dinputtitle">
                                        price
                                    </div>
                                    <input
                                        value={this.state.price}
                                        onChange={(e)=>{
                                        this.setState({price:e.target.value})
                                        }}
                                    />
                                </div>
                                <div className="dinputitem">
                                    <div className="dinputtitle">
                                        note
                                    </div>
                                    <input
                                        value={this.state.note}
                                        onChange={(e)=>{
                                        this.setState({note:e.target.value})
                                        }}
                                    />
                                </div>

                            </div>
                            <button onClick={()=>{
                                if(this.state.dur != '') {
                                    this.setState({
                                        treatment: {
                                            ...this.state.treatment,details : [...this.state.treatment.details, {duration:parseInt(this.state.dur),price:parseInt(this.state.price),note:this.state.note}]
                                        },
                                        dur:'',
                                        price:'',
                                        note:''
                                    })
                                }

                            }}>add</button>
                        </div>
                </div>
                <div className="tgroup">
                    <div className="ttitle">Components</div>
                    <textarea className="ttextarea"
                        value={this.state.treatment.components}
                        onChange={(e)=>{
                            this.setState({
                                treatment: {
                                    ...this.state.treatment,
                                    components:e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className="tgroup">
                    <div className="ttitle">Outcome</div>
                    <textarea className="ttextarea"
                        value={this.state.treatment.outcome}
                        onChange={(e)=>{
                            this.setState({
                                treatment: {
                                    ...this.state.treatment,
                                    outcome:e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className="tgroup">
                    <div className="ttitle">Description</div>
                    <textarea className="ttextarea"
                        value={this.state.treatment.description}
                        onChange={(e)=>{
                            this.setState({
                                treatment: {
                                    ...this.state.treatment,
                                    description:e.target.value
                                }
                            })
                        }}
                    />
                </div>
                <div className="tgroup">
                    <div className="ttitle">Price</div>
                    {this.state.treatment.price.map((p,index)=>{
                            return (
                                <div key={index} className="tattribute">{p}<div className="taclose"
                                    onClick={()=>{
                                        var array = [...this.state.treatment.price]
                                        array.splice(index,1);
                                        this.setState({
                                            treatment : {
                                                ...this.state.treatment, price:array
                                            }
                                        })
                                    }}
                                >x</div></div>

                            )
                        })}
                         <div className="tanew">
                            <input
                                value={this.state.price}
                                onChange={(e)=>{
                                    this.setState({price:e.target.value})
                                }}
                            />
                            <button onClick={()=>{
                                if(this.state.price != '') {
                                    this.setState({
                                        treatment: {
                                            ...this.state.treatment,price : [...this.state.treatment.price, parseInt(this.state.price)]
                                        },
                                        price:''
                                    })
                                }

                            }}>add</button>
                        </div>
                </div>
                <div className="tbottom">
                        <div className="tbottomgroup">
                <div className="tgroup">
                    <div className="tupdate">
                        <button
                            onClick={()=>{
                                this.update()
                            }}
                        >update</button>
                    </div>
                </div>
                <div className="tgroup">
                    <div className="tupdate">
                        <button
                            onClick={()=>{
                                this.props.navigate('/admin/treatments')
                            }}
                        >cancel</button>
                    </div>
                </div>
                </div>
                <div className="tbottomgroup">
                <div className="tgroup">
                    <div className="tupdate">
                        <button
                            onClick={()=>{
                                this.delete()
                            }}
                        >delete</button>
                    </div>
                </div>
                </div>
                </div>

            </div>
        )
    }
}


export default withNavigation(EditTreatment)