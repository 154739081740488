import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../util/api';
import './orderview.css';

const  withParams = (Component : Component) => {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
  } 
class OrderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order:'',
            loading:true
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/order/list/' + this.props.params.id,
        }).then(res=>{
            console.log('GOT ORDER',res.data)
            this.setState({
                loading:false,
                order:res.data
            })
        }).catch(error=>{
            console.log('error loading order',error)
        })
    }
    delete() {
        console.log(this.state.order._id)
        API.fetch({
            method:'POST',
            endpoint:'/order/delete/',
            data:{
                id:this.state.order._id
            }
        }).then(res=>{
            console.log('deleted', res.data);
            this.props.navigate(-1)
        }).catch(error=>{
            console.log('ERROR DELETEING ORDER')
        })
    }
    render() {
        var {id} = this.props.params;
        console.log('order view',this.props)
        console.log('THE ORDER IS',this.state.order)
        return this.state.loading ? (<div>loading</div>) : (
            <div style={{padding:25}}>
                <div className="adminOrderWrap">
                    <div className="adminOrderGroup">
                        <div className="adminOrderTitle">
                            Name
                        </div>
                        <div className="adminOrderData">
                            {this.state.order.name}
                        </div>
                    </div>
                    <div className="adminOrderGroup">
                        <div className="adminOrderTitle">Chosen</div>
                        <div className="adminOrderData">
                            {this.state.order.chosen.map(c=>{
                                        return (
                                            <div style={{marginLeft:10,backgroundColor:'#efefef',padding:15,marginBottom:12,borderRadius:4}}>{c.title} - {c.selectedDuration.duration} minutes</div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
                <div 
                style={{
                    background:'#dedede',
                    display:'inline-block',
                    textAlign:'center',
                    padding:6,
                    width:70,
                    marginTop:10,
                    cursor:'pointer'
                }}
                onClick={()=>{
                    this.props.navigate(-1)
                }}>back</div>
                <div
                                style={{
                                    background:'#ff0000',
                                    color:'white',
                                    display:'inline-block',
                                    textAlign:'center',
                                    padding:6,
                                    width:70,
                                    marginTop:10,
                                    marginLeft:10,
                                    cursor:'pointer'

                                }}
                
                onClick={()=>{
                    this.delete()
                }}>delete</div>

            </div>
        )
    }
}
export default withParams(OrderView)