import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
    setName,
    setArrived,
    setDeparting,
    setEmail,
    setVilla,
    setBG
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/buttons.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    FormHelperText,
    Flex,
    Center,
    Box
} from '@chakra-ui/react'

import API from '../util/api';
import Select from 'react-select';


import { SingleDatepicker } from "chakra-dayzed-datepicker";


class BasicInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guardians : []
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/bg/list'
        }).then(res=>{
            this.setState({
                guardians:res.data
            })
        }).catch(error=>{
            console.log('error loading barefoot guardians')
        })
    }

    render() {
        const {onboard} = this.props;
        return (
                    <Flex width="full" direction="column" p="5" 
                    style={{
                        justifyContent:'center'
                        }} 
                    align="center"
                    >
                        <Box p={10} boxShadow="xl" className="ibw">
                    <FormControl>
                          <FormLabel>Name</FormLabel>
                          <Input type='text'
                            value={onboard.name}
                            onChange={(e)=>{
                                this.props.setName(e.target.value)
                            }}
                            boxShadow='base'
                            rounded="xl"
                            size="lg"
                          />
                    </FormControl>
                    {/* <FormControl mt={10}>
                          <FormLabel>Last name</FormLabel>
                          <Input type='text'
                            value={onboard.lastname}
                            onChange={(e)=>{
                                this.props.setLastname(e.target.value)
                            }}
                            boxShadow='md'
                            rounded="xl"
                            size="lg"
                          />
                    </FormControl> */}
                    <FormControl mt={10}>
                            <FormLabel>Arrival</FormLabel>
                        <SingleDatepicker
                            date={onboard.arrived}
                            onDateChange={(e)=>{
                                this.props.setArrived(e)
                            }}
                            configs={{
                                dateFormat: 'MM-dd-yyyy',
                            }}
                            boxShadom="md"
                            propsConfigs={{
                                inputProps : {
                                    size:"lg",
                                    rounded:"xl",
                                    boxShadow:'md'
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl mt={10}>
                            <FormLabel>Departing</FormLabel>
                        <SingleDatepicker
                            date={onboard.departing}
                            onDateChange={(e)=>{
                                this.props.setDeparting(e)
                            }}
                            configs={{
                                dateFormat: 'MM-dd-yyyy',
                            }}
                            boxShadom="md"
                            propsConfigs={{
                                inputProps : {
                                    size:"lg",
                                    rounded:"xl",
                                    boxShadow:'lg'
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl>
                          <FormLabel>Email</FormLabel>
                          <Input type='text'
                            value={onboard.email}
                            onChange={(e)=>{
                                this.props.setEmail(e.target.value)
                            }}
                            boxShadow='base'
                            rounded="xl"
                            size="lg"
                          />
                    </FormControl>
                    <FormControl>
                          <FormLabel>Villa #</FormLabel>
                          <Input type='text'
                            value={onboard.villa}
                            onChange={(e)=>{
                                this.props.setVilla(e.target.value)
                            }}
                            boxShadow='base'
                            rounded="xl"
                            size="lg"
                          />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Barefoot Guardians</FormLabel>
                        <Select options={this.state.guardians.map(e=>{
                            return {value:JSON.stringify(e),label:e.name}
                        })}
                        isMulti
                        onChange={(e)=>{
                            console.log(e)
                            this.props.setBG(e.map(s=>{
                                return JSON.parse(s.value)
                            }))
                        }}
                        menuPlacement="top"
                        />
                    </FormControl>
                    </Box>
                    </Flex>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default connect(mapStateToProps,{
    setName,
    setArrived,
    setDeparting,
    setEmail,
    setVilla,
    setBG
})(BasicInfo)