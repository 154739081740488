import React from 'react';
import './css/footer.css';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";

import { useNavigate, useParams, useLocation } from 'react-router-dom';

import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    FormHelperText,
    Flex,
    Center,
    Box
} from '@chakra-ui/react'

import { setHeaderTitle } from '../redux/actions/navactions';
const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} location={useLocation()}/>;
  } 

  const sb = () => {
    return (
        <div style={{backgroundColor:'red'}}>dwdwdwhey</div>
    )
}


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        console.log('footer props',this.props)
    }
    catch() {
            switch(this.props.location.pathname) {
                case "/":
                    this.props.setHeaderTitle("Welcome")
                    return (
                        <div className="fbg">
                        <Link to="/info" className="fb">Next - Basic info</Link>
                        </div>
                    )
                    break;
                case "/info":
                    this.props.setHeaderTitle("Basic Info")
                    return (
                        <div className="fbg">
                        <Link to="/" className="fb">Previous - Home</Link>
                        <Link to="/feeling" className="fb">Next - Feeling</Link>
                        </div>
                    )
                    break;
                case "/feeling":
                    this.props.setHeaderTitle("How are you Feeling?")
                        return (
                            <div className="fbg">
                                <Link to="/info" className="fb">Previous - Basic Info</Link>
                                <Link to="/symptoms" className="fb">Next - Symptoms</Link>
                            </div>
                        )
                        break;
                case "/symptoms":
                    this.props.setHeaderTitle("Symptoms")
                        return (
                            <div className="fbg">
                                <Link to="/feeling" className="fb">Previous - Feeling</Link>
                                <Link to="/wellness" className="fb">Next - Wellness</Link>
                            </div>
                        )
                        break;        
                case "/wellness":
                    this.props.setHeaderTitle("Wellness")
                            return (
                                <div className="fbg">
                                    <Link to="/symptoms" className="fb">Previous - Symptoms</Link>
                                    <Link to="/order" className="fb">Next - Order</Link>
                                </div>
                            )
                        break;
                case "/order":
                    this.props.setHeaderTitle("Order")
                                return (
                                    <div className="fbg">
                                        <Link to="/wellness" className="fb">Previous - Wellness</Link>
                                    </div>
                                )
                                break;
                default:
                    this.props.setHeaderTitle("Welcome")
                    return (
                        <div className="fbg">
                        <Link to="/" className="fb">Basic info</Link>
                        </div>
                    )
                    break;
            }
    }
    render() {
        const {pathname} = this.props.location;   
        return (
            <div className="footerwrap">
                {this.catch()}
            </div>
        )
    }
}

export default withNavigation(connect(
    null,
    {setHeaderTitle}
)(Footer))