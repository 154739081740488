import {baseUrl} from '../config.js';
import axios from 'axios';

class API {
    fetch({method,endpoint,data}) {
        return new Promise(function(resolve,reject){
            axios({
                method:method,
                url: baseUrl + endpoint,
                data:data,
               
            }).then(result=>{
                resolve(result);

            }).catch(error=>{
                reject(error)
            })
        })
    }
}

export default new API();