import React from 'react';
import API from '../util/api';
import { useNavigate, useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import { loadExcel,setindex, toggleActiveJani, toggleActiveFushi,toggleActiveKiri,toggleActiveSecret } from '../redux/actions/excelactions';
const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 

function compare( a, b ) {
    if ( a.category < b.category ){
      return -1;
    }
    if ( a.category > b.category ){
      return 1;
    }
    return 0;
  }

class Excel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list:[],
            loading:true
        }
         
    }
    componentDidMount() {
        this.props.loadExcel()
    }
    render() {
        console.log(this.props)
        return this.props.excel.loading ? (
            <div>loading</div>
        ) : (
            <div style={{padding:25}}>
                <table>
                    <thead>
                        <th>category</th>
                        <th>title</th>
                        <th>description</th>
                        <th>triggers</th>
                        <th>active @ jani</th>
                        <th>active @ fushi</th>
                        <th>active @ kiri</th>
                        <th>active @ secret</th>

                        <th>edit</th>
                    </thead>
                    <tbody>
                {/* {this.props.excel.excel.sort(compare).map((l,index)=>{ */}
                {this.props.excel.excel.map((l,index)=>{
                    return (
                        <tr>
                            <td style={{border:'1px solid black',padding:8}}>{l.category}</td>
                            <td style={{border:'1px solid black',padding:8}}>{l.title}</td>
                            <td style={{border:'1px solid black',padding:8}}>{l.description}</td>
                            <td style={{border:'1px solid black',padding:8}}>{l.triggers.map(t=>t + ', ')}</td>
                            <td style={{border:'1px solid black',padding:8,width:120,textAlign:'center'}}><div className="button" onClick={()=>{this.props.toggleActiveJani(l)}}
                            style={{
                                backgroundColor:l.activejani ? 'green' : 'red',
                                color:'white'
                            }}
                            >{l.activejani ? 'active' : 'inactive'}</div></td>
                            <td style={{border:'1px solid black',padding:8,width:120,textAlign:'center'}}><div className="button" onClick={()=>{this.props.toggleActiveFushi(l)}}
                            style={{
                                backgroundColor:l.activefushi ? 'green' : 'red',
                                color:'white'
                            }}
                            >{l.activefushi ? 'active' : 'inactive'}</div></td>
                            <td style={{border:'1px solid black',padding:8,width:120,textAlign:'center'}}><div className="button" onClick={()=>{this.props.toggleActiveKiri(l)}}
                            style={{
                                backgroundColor:l.activekiri ? 'green' : 'red',
                                color:'white'
                            }}
                            >{l.activekiri ? 'active' : 'inactive'}</div></td>
                            <td style={{border:'1px solid black',padding:8,width:120,textAlign:'center'}}><div className="button" onClick={()=>{this.props.toggleActiveSecret(l)}}
                            style={{
                                backgroundColor:l.activesecret ? 'green' : 'red',
                                color:'white'
                            }}
                            >{l.activesecret ? 'active' : 'inactive'}</div></td>

                            <td style={{border:'1px solid black',padding:8}}><div className="button"
                                                    onClick={()=>{
                                                        this.props.setindex(index)
                                                        this.props.navigate('/admin/excel/edit' )
                                                    }}
                            >edit</div></td>
                        </tr>
                    )
                })}
                    </tbody>
                    </table>
                    <div 
                    style={{cursor:'pointer',
                    marginTop:10,
                    backgroundColor:'#efefef',
                    width:100,
                    textAlign:'center',
                    padding:10
                }}
                    onClick={()=>{
                        this.props.navigate('/admin/excel/create')
                    }}>Create</div>
            </div>
        )
    }
}
const mapStateToPRops = state => ({
    excel:state.excel
})
export default withNavigation(connect(mapStateToPRops,{loadExcel,setindex,toggleActiveJani,toggleActiveFushi,toggleActiveKiri,toggleActiveSecret})(Excel))