import React from 'react';
import './swipescreens/css/screen.css';

import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';
import {
    login
} from './redux/actions/authActions';
import logo from './assets/logo_green.png';
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            password:''
            
        }
    }
login = () => {
    if(this.state.email == 'welcome@soneva.com' && this.state.password =='soneva2024') {
        this.props.login()
    } else {
        window.alert('Invalid user / password')
    } 
}
    render() {
        return this.props.auth.isAuthenticated ? <Navigate to="/"/> :(
            <div style={{
                width:'100%',
                height:'100vh',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'


            }}>
                <div style={{
                    width:'100%',
                    maxWidth:400,
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    padding:10

                }}>
                    <img src={logo} style={{marginBottom:20,width:150}}/>
                    <input 
                        value={this.state.email}
                        onChange={(e)=>{
                            this.setState({
                                email:e.target.value
                            })
                        }}
                        placeholder="email"
                        style={{
                            width:"100%",
                            border:'1px solid #cdcdcd',
                            borderRadius:5,
                            padding:10
                        }}
                        />
                    <input 
                        value={this.state.password}
                        onChange={(e)=>{
                            this.setState({
                                password:e.target.value
                            })
                        }}
                        placeholder="password"
                        style={{
                            width:"100%",
                            border:'1px solid #cdcdcd',
                            borderRadius:5,
                            padding:10,
                            marginTop:15
                        }}
                        />
                        <div style={{
                            marginTop:20,
                            width:'100%',
                            textAlign:'center',
                            cursor:'pointer'
                        }}
                        onClick={this.login}
                        >
                            login
                        </div>

                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
  });
  
  export default connect(mapStateToProps,{login})(Login)