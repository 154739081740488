import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
    setName,
    setArrived,
    setDeparting
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/buttons.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    FormHelperText,
    Flex,
    Center,
    Box
} from '@chakra-ui/react'



import { SingleDatepicker } from "chakra-dayzed-datepicker";

import { useNavigate, useParams } from 'react-router-dom';
const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 

class BasicInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }

    render() {
        const {onboard} = this.props;
        console.log(this.props)
        return (
                    <Flex width="full" direction="column" p="5" 
                    style={{
                        justifyContent:'center'
                        }} 
                    align="center"
                    >
                        <Box p={10} boxShadow="xl" width="80%">
                    <FormControl>
                          <FormLabel>First name</FormLabel>
                          <Input type='text'
                            value={onboard.name}
                            onChange={(e)=>{
                                this.props.setName(e.target.value)
                            }}
                            boxShadow='base'
                            rounded="xl"
                            size="lg"
                          />
                    </FormControl>
                    <FormControl mt={10}>
                            <FormLabel>Arrival</FormLabel>
                        <SingleDatepicker
                            date={onboard.arrived}
                            onDateChange={(e)=>{
                                this.props.setArrived(e)
                            }}
                            configs={{
                                dateFormat: 'MM-dd-yyyy',
                            }}
                            boxShadom="md"
                            propsConfigs={{
                                inputProps : {
                                    size:"lg",
                                    rounded:"xl",
                                    boxShadow:'md'
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl mt={10}>
                            <FormLabel>Departing</FormLabel>
                        <SingleDatepicker
                            date={onboard.departing}
                            onDateChange={(e)=>{
                                this.props.setDeparting(e)
                            }}
                            configs={{
                                dateFormat: 'MM-dd-yyyy',
                            }}
                            boxShadom="md"
                            propsConfigs={{
                                inputProps : {
                                    size:"lg",
                                    rounded:"xl",
                                    boxShadow:'lg'
                                }
                            }}
                        />
                    </FormControl>
                    </Box>
                    </Flex>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default withNavigation(connect(mapStateToProps,{
    setName,
    setArrived,
    setDeparting
})(BasicInfo))