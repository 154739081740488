import API from '../../util/api';

import {
    UPDATE_SENDTO,
    LOAD_SENDTO_START,
    LOAD_SENDTO_SUCCESS,
    ADD_SENDTO,
    REMOVE_SENDTO,
    UPDATE_TITLE,
    UPDATE_MESSAGE,
    UPDATE_SALUTATION
} from './types';


export const loadsendto = () => dispatch => {
    return new Promise(function(resolve,reject){
        dispatch(loadsendtostart);
        API.fetch({
            method:'GET',
            endpoint:'/admin/list'
        }).then(result=>{
            console.log('THE SEND TO LOAD RESULT DATA',result.data[0].sendto)
            dispatch(loadsendtosuccess(result.data[0]))
            resolve(result.data)
        }).catch(error=>{
            dispatch(loadsendtosuccess([]));
            reject('error')
        })

    })
}

const loadsendtostart = () => {
    return {
        type:LOAD_SENDTO_START
    }
}

const loadsendtosuccess = (data) => {
    return {
        type:LOAD_SENDTO_SUCCESS,
        payload:data
    }
}

export const addsendto = (data) => {
    return {
        type:ADD_SENDTO,
        payload:data
    }
}
export const removesendto = (data) => {
    return {
        type:REMOVE_SENDTO,
        payload:data
    }
}

export const updateTitle = (data) => {
    return {
        type:UPDATE_TITLE,
        payload:data
    }
}

export const updateMessage = (data) => {
    return {
        type:UPDATE_MESSAGE,
        payload:data
    }
}

export const updateSalutation = (data) => {
    return {
        type:UPDATE_SALUTATION,
        payload:data
    }
}

export const updatesendto = (data) => dispatch => {
    return new Promise(function(resolve,reject){
        console.log('THE DATA',data)
        API.fetch({
            method:'POST',
            endpoint:'/admin/update',
            data:{"sendto":data}
        }).then(result=>{
            resolve(result.data)
        }).catch(error=>{
            reject('error')
        })

    })
}