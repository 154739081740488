import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
    setWellness
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/wellness.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Wellness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    updatesym = (sym) => {
        var newsym = [...this.props.onboard.wellness];

        var i = newsym.findIndex(e=>e.q == sym.q);
        newsym[i].s = !newsym[i].s;
        this.props.setWellness(newsym)
    }
    clear = () => {
        var newsym = [...this.props.onboard.wellness];
        for(let i=0;i<newsym.length;i++) {
            newsym[i].s = false;
        }
        this.props.setWellness(newsym);
    }
    render() {
        console.log('WELLENSS SCREEP',this.props)
        const {wellness} = this.props.onboard;
        return (
            <div className="wellnesswrap">
                <div className="wellnessitems">
                    {wellness.map(w=>{
                        return (
                            <div className="wellnessitem" style={{
                                backgroundColor:w.s ? '#5c8c9b' : 'lightblue',
                                color:w.s ? 'white' : '#404040'
                            }}
                            onClick={()=>{
                                this.updatesym(w)
                            }}
                            >{w.q}</div>
                        )
                    })}
                </div>    
                <div className="wellnessclear"
                    onClick={()=>{
                        this.clear();
                    }}
                >[clear]</div>
            </div>
            )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default connect(mapStateToProps,{
    setWellness
})(Wellness)