import {
    UPDATE_SENDTO,
    LOAD_SENDTO_START,
    LOAD_SENDTO_SUCCESS,
    ADD_SENDTO,
    REMOVE_SENDTO,
    UPDATE_TITLE,
    UPDATE_MESSAGE,
    UPDATE_SALUTATION
} from '../actions/types'

const initialState = {
    sendto: [],
    loading:true,
    title:'',
    message:'',
    salutation:''
}

export default function(state = initialState, action) {
    switch(action.type) {
        case UPDATE_SENDTO:
            return {
                ...state,
                sendto:action.payload
            }
            break;
        case LOAD_SENDTO_START:
            return {
                ...state,
                loading:true
            }
            break;
        case LOAD_SENDTO_SUCCESS:
            return {
                ...state,
                loading:false,
                sendto:action.payload.sendto,
                title:action.payload.title,
                message:action.payload.message,
                salutation:action.payload.salutation
            }
        case ADD_SENDTO:
            return {
                ...state,
                sendto:[...state.sendto,action.payload]                
            }
            break;
        case REMOVE_SENDTO:
                return {
                    ...state,
                    sendto:[...state.sendto.filter(e=>e != action.payload)]
                }
        case UPDATE_TITLE:
            return {
                ...state,
                title:action.payload
            }
            break;
        case UPDATE_MESSAGE:
            return {
                ...state,
                message:action.payload
            }
            break;
        case UPDATE_SALUTATION:
            return {
                ...state,
                salutation:action.payload
            }
            break;
        default:
            return state;
    }
}

