import React from 'react';
import API from '../util/api';
import { imageUrl } from '../config';
import { useNavigate, useParams } from 'react-router-dom';
const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 


class Specialist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list:[],
            loading:true
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/specialist/list'
        }).then(res=>{
            this.setState({
                list:res.data,
                loading:false
            })
        }).catch(error=>{
            console.log('error loading specialists',error)
        })
    }
    render() {
        return this.state.loading ? null : (
            <div style={{
                padding:15
            }}>
                <div style={{
                    padding:10,
                    width:600,
                    marginBottom:15
                }}>
                    {this.state.list.map(l=>{
                        return (
                            <div style={{
                                backgroundColor:'#efefef',
                                marginBottom:20,
                                padding:10
                            }}>
                                <div style={{fontWeight:'bold',marginBottom:10}}>{l.name}</div>
                                <div style={{marginBottom:15}}>{l.bio}</div>
                                <div><img src={imageUrl + l.image} style={{width:'100%'}}/></div>
                                <div className="button" onClick={()=>{
                                    this.props.navigate('/admin/specialist/edit',{state:{id:l._id}})
                                }}>edit</div>
                            </div>
                        )
                    })}
                </div>
                <div className="button" onClick={()=>{
                    this.props.navigate('/admin/specialist/create')
                }}>add</div>
            </div>
        )
    }
}

export default withNavigation(Specialist)