import {
    LOAD_EXCEL_START,
    LOAD_EXCEL_FINISH,
    UPDATE_EXCEL_START,
    UPDATE_EXCEL_FINISH,
    SET_INDEX,
    INC_INDEX,
    DEC_INDEX,
    EDIT_EXCEL,
    TOGGLE_ACTIVE_JANI,
    TOGGLE_ACTIVE_FUSHI,
    TOGGLE_ACTIVE_KIRI,
    TOGGLE_ACTIVE_SECRET
} from '../actions/types'

const initialState = {
    excel : [],
    loading:true,
    loaded:false,
    index:0
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOAD_EXCEL_START:
            return {
                ...state,
                loading:true
            }
            break;
            case LOAD_EXCEL_FINISH:
                return {
                    ...state,
                    loading:false,
                    loaded:true,
                    excel:action.payload
                }
            break;
        case SET_INDEX:
                return {
                    ...state,
                    index:action.payload
                }
            break;
        case INC_INDEX:
                return {
                    ...state,
                    index:state.index + 1
                }
                break;
        case DEC_INDEX:
                return {
                    ...state,
                    index: state.index - 1
                }
        case EDIT_EXCEL:
                console.log('edit excel in reducer')
                console.log(state.excel[action.payload.index])
                return {
                    ...state,
                    excel: state.excel.map((f,i)=> i === action.payload.index ? {...f, [action.payload.field]:action.payload.value} : f)
                }
        case TOGGLE_ACTIVE_JANI:
            console.log(action.payload);
            return {
                ...state,
                excel:state.excel.map(f=>{
                    if(f._id === action.payload._id) {
                        return action.payload
                    }
                    return f;
                })
            }
            break;
            case TOGGLE_ACTIVE_FUSHI:
                console.log(action.payload);
                return {
                    ...state,
                    excel:state.excel.map(f=>{
                        if(f._id === action.payload._id) {
                            return action.payload
                        }
                        return f;
                    })
                }
                break;
            case TOGGLE_ACTIVE_KIRI:
                console.log(action.payload);
                return {
                    ...state,
                    excel:state.excel.map(f=>{
                        if(f._id === action.payload._id) {
                            return action.payload
                        }
                        return f;
                    })
                }
                break;
            case TOGGLE_ACTIVE_SECRET:
                console.log(action.payload);
                return {
                    ...state,
                    excel:state.excel.map(f=>{
                        if(f._id === action.payload._id) {
                            return action.payload
                        }
                        return f;
                    })
                }
                break;
            
        default:
            return state;
    }
}

