import {combineReducers} from 'redux';
import onboard from './onboard';
import nav from './nav';
import excel from './excel';
import admin from './admin';
import auth from './auth';

export default combineReducers({
    onboard:onboard,
    nav:nav,
    excel:excel,
    admin:admin,
    auth:auth
})