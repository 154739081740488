import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
    setTreatments
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/order.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Symptoms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    updatetreatment = (t) => {
        console.log('updating',t)
        var newt = [...this.props.onboard.treatments];

        var i = newt.findIndex(e=>e.t == t.t);
        newt[i].s = !newt[i].s;
        this.props.setTreatments(newt)
    }
    updatemultitreatment = (t,treatment) => {
        console.log('multi update',t,treatment)
        var newt = [...this.props.onboard.treatments];
        var i = newt.findIndex(e=>e.t == treatment.t);
        console.log('found index',i)
        console.log('new t', newt[i])
         var j = newt[i].a.findIndex(e=>e.d == t.d);
        console.log('the j index',j)
        newt[i].a[j].s = !newt[i].a[j].s;
        this.props.setTreatments(newt)
        //find the index of the treatment we are updating.

    }
    render() {
        const {symptoms,treatments} = this.props.onboard;
        console.log('order page symptomes',symptoms)
        console.log('selected tgreatments',treatments)
        return (
                <div className="welcomeWrap"
                >
                    <div className="contentBox">
                        <div className="screenTitleBig">Order</div>
                        <div className="screenDescription">Select to order services</div>
                        <div className="orderwrap">
                            {symptoms.filter(e=>e.s === true).map(s=>{
                                console.log(s.t)
                                var treatment_index = treatments.findIndex(e=>e.t == s.t);
                                console.log(treatment_index)
                                if(treatment_index >= 0) {
                                    var treatment = treatments[treatment_index]

                                } else {
                                    var treatment= treatments[1]
                                }
                            return (
                                    <div className="orderitem">
                                        <div className="orderdescription">{treatment.d}</div>
                                        {treatment.b == "single" ? (
                                        <div className="orderbutton" style={{background:treatment.s ? 'lightskyblue' : 'seashell'}}
                                        onClick={()=>{
                                            this.updatetreatment(treatment)
                                        }}
                                        >
                                            <div className="obtextwrap">{treatment.a}</div>
                                            <div className="obbuttonwrap">
                                                <div className="obaction">Select</div>
                                                <div className="obaction">Info</div>
                                            </div>
                                            </div>


                                        ) : (
                                            <div className="ordermultiwrap">
                                                {treatment.a.map(t=>{
                                                    return (
                                                            <div className="orderbutton" style={{background:t.s ? 'lightskyblue' : 'seashell'}}
                                                            onClick={()=>{
                                                                this.updatemultitreatment(t,treatment)
                                                            }}
                                                            >{t.d}</div>
)
                                                })}
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="ordernoteswrap">
                            <div className="ordernotestitle">Notes</div>
                            <textarea className="ordernotestextarea"/>
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default connect(mapStateToProps,{
    setTreatments
})(Symptoms)