import React from 'react';
import API from '../util/api';
import "./admin.css";
import {Link} from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';

export const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 


class Treatments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            treatments:[]
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/treatments/list',
        }).then(res=>{
            console.log(res.data)
            this.setState({
                loading:false,
                treatments:res.data
            })
        })
    }

    render() {
        return this.state.loading ? (
            <div>treatments loading</div>
        ) : (
            <div className="tcontainer">
                {this.state.treatments.map((t,index)=>{
                return (
                        <div key={t._id} className="twrap" style={{'backgroundColor':index %2 == 0 ? '#f2f2f2':'white'}}>
                            <div className="tcol">{t.categorytitle}</div>
                            <div className="tcol">{t.subcategory}</div>
                            <div className="tcol">{t.title}</div>
                            <div className="tcol">{t.outcome}</div>
                            <div className="tcol">{t.attributes.map((a,i)=>{return a + (i < t.attributes.length - 1 ? ", " : "")})}</div>
                            <div className="tcol"><Link to={"/admin/treatments/" +t._id}><button>edit</button></Link></div>
                        </div>
                    )
                })}
                <div>{this.state.treatments.length}</div>
            <button 
                onClick={()=>{
                    this.props.navigate('/admin/treatments/new')
                }}>new</button>
            </div>
        )
    }
}

export default withNavigation(Treatments)