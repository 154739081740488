import React from 'react';
import API from '../util/api';
import axios from 'axios';
import { baseUrl, imageUrl, avail } from '../config';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} location={useLocation()} />;
  } 

class EditSpecialist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            s: null,
            loading:true
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/specialist/list/' + this.props.location.state.id
        }).then(res=>{
            this.setState({
                s:res.data,
                loading:false
        })})

    }

    submit = () => {
        console.log(this.state)
        API.fetch({
            method:"POST",
            endpoint:"/specialist/update",
            data:{
                specialist:this.state.s
            }
        }).then(res=>{
            console.log(res.data)
        }).catch(error=>{
            console.log('AN ERROR!')
        })
    }
    render() {
        console.log(this.state)
        return this.state.loading ? null : (
            <div style={{padding:15,backgroundColor:'#efefef',width:600}}>Create Specialist
            <div>
                {this.state.s.image ? (
                    <img src={imageUrl + this.state.s.image} style={{width:300,marginTop:10,marginBottom:10}}/>
                ) : null}
            <input
            style={{marginTop:10}}
                type="file"
                name="image"
                onChange={(e) => {
                console.log(e.target.files[0]);
                    const formData = new FormData();
                    formData.append('image',e.target.files[0])
                    API.fetch({
                        method:"POST",
                        endpoint:"/upload",
                        data:formData
                    }).then(res=>{
                        console.log('THE RES UPLOAD',res.data)
                        this.setState({
                            s:{...this.state.s,image:res.data.filename}
                        })
                    }).catch(error=>{
                        console.log('ERROR OF UPLOAD',error)
                    })
                }}
            />
                </div>
                <div className="formItem" style={{marginTop:15,marginBottom:15}}>
                    <div className="formTitle" style={{fontWeight:'bold'}}>Name</div>
                    <div className="formInput">
                        <input
                        style={{width:'100%'}}
                            value={this.state.s.name}
                            onChange={(e)=>{
                                this.setState({
                                    s:{...this.state.s, name:e.target.value}
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="formItem">
                    <div className="formTitle" style={{fontWeight:'bold'}}>Bio</div>
                    <div className="formInput">
                        <textarea
                        style={{width:'100%',height:300,marginBottom:10}}
                            value={this.state.s.bio}
                            onChange={(e)=>{
                                this.setState({
                                    s:{...this.state.s, bio:e.target.value}
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="formItem" style={{marginBottom:30}}>
                    <div className="formTitle" style={{fontWeight:'bold'}}>Location</div>
                    <div className="formInput">
                        <select value={this.state.s.location}
                            onChange={(e)=>{
                                this.setState({
                                    s:{...this.state.s, location:e.target.value}
                                })
                            }}
                        >
                            {avail.map(a=>{
                                return (
                                    <option value={a}>{a}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className="button"
                    onClick={()=>{
                        this.submit()
                    }}
                >submit</div>
                </div>
        )
    }
}

export default withNavigation(EditSpecialist)