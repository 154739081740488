import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import { loadExcel,setindex, incindex, decindex, editexcel } from '../redux/actions/excelactions';
import API from '../util/api';
import _ from 'underscore';

const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 
const avail = ['fushi','jani']
const triggers = [
    'chronic',
    'tired',
    'relax',
    'jetlag',
    'pain',
    'optimize',
    'weight',
    'sleep',
    'tension',
    'fitness',
    'beauty'
]
class ExcelCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            r:{
                description:'',
                title:'',
                components:'',
                outcome:'',
                category:'massage',
                triggers:['relax'],
                details:[{duration:30,price:100,note:''}],
                availability:['fushi','jani'],
                triggers:['relax']
            },
            loading:true,
            duration:30,
            price:120,
            note:''
        }
    }
    async componentDidMount() {
    }
    componentDidUpdate() {
    }
    render() {
        console.log(this.state)
        return (
            <div className="mainwindow">
                    <div className="button"
                        onClick={()=>{this.props.navigate(-1)}}
                    >back to list</div>
                <div className="editwrap">
                <div className="editcol">
                <div className="editform">
                <div className="edititem">
                    <div className="inputtitle">Title</div>
                    <input
                        className="editinput"
                        value={this.state.r.title}
                        onChange={(e)=>{
                            this.setState({
                                r:{...this.state.r,title:e.target.value}
                            })
                        }}
                        />
                </div>
                <div className="edititem">
                    <div className="inputtitle">Description</div>
                    <textarea
                        className="editinput textedit"
                        value={this.state.r.description}
                        onChange={(e)=>{
                            this.setState({
                                r:{...this.state.r,description:e.target.value}
                            })
                        }}
                        />
                </div>
                {/* <div className="edititem">
                    <div className="inputtitle">Components</div>
                    <textarea
                        className="editinput textedit"
                        value={this.state.r.components}
                        onChange={(e)=>{
                            this.setState({
                                r:{...this.state.r,components:e.target.value}
                            })
                        }}
                        />
                </div>
                <div className="edititem">
                    <div className="inputtitle">Outcome</div>
                    <textarea
                        className="editinput textedit"
                        value={this.state.r.outcome}
                        onChange={(e)=>{
                            this.setState({
                                r:{...this.state.r,outcome:e.target.value}
                            })
                        }}
                        />
                </div> */}

                <div className="edititem">
                    <div className="inputtitle">Category</div>

                    <select value={this.state.r.category}
                        onChange={(e)=>{
                            this.setState({
                                r:{...this.state.r,category:e.target.value}
                            })
                        }}
                        className="editinput"
                    >
                        <option value="massage">massage</option>
                        <option value="movement">movement</option>
                        <option value="beauty">beauty</option>
                        <option value="tcm">tcm</option>
                        <option value="nrmed">nrmed</option>
                        <option value="ayurveda">ayurveda</option>
                        <option value="mdnurse">mdnurse</option>

                    </select>

                </div>
                </div>
                </div>
                <div className="editcol">
                <div className="editform">



                </div>
                </div>

                </div>
                <div className="bottom">
                    <div className="buttonrow">
 
                        </div>
                        <div className="button"
                        onClick={async ()=>{
                            try {
                            var res =  await API.fetch({
                                method:'POST',
                                endpoint:'/excel/create',
                                data:{excel:this.state.r}
                            })
                            this.props.navigate(-1)
                        } catch(error) {

                        }

                        }}
                        >create</div>
                </div>
             
            </div>
        )
    }
}

const mapStateToProps = state => ({
    excel:state.excel
})

export default withNavigation(connect(mapStateToProps,{loadExcel,incindex,decindex,setindex,editexcel})(ExcelCreate))