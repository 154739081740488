import React from 'react';

import API from '../util/api';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 


class BGs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bgs:[],
            loading:true
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/bg/list'
        }).then(res=>{
            this.setState({
                bgs:res.data,
                loading:false
            })
        })
    }
    render() {
        return this.state.loading ? null : (
            <div style={{padding:15}}>
                <table style={{width:'100%'}}>
                    <thead>
                        <th className="bgth">name</th>
                        <th className="bgth">email</th>
                        <th className="bgth">email active</th>
                        <th className="bgth">whats app</th>
                        <th className="bgth">whatsapp active</th>
                        <th className="bgth">location</th>
                        <th className="bgth">edit</th>

                    </thead>
                    <tbody>
                    {this.state.bgs.map(b=>{
                        return (
                            <tr onClick={()=>{
                            }}>
                                <td className="bgtr">{b.name}</td>
                                <td className="bgtr">{b.email.email}</td>
                                <td className="bgtr">{b.email.active ? 'active' : 'inactive'}</td>
                                <td className="bgtr">{b.whatsapp.whatsapp}</td>
                                <td className="bgtr">{b.whatsapp.active ? 'active' : 'inactive'}</td>
                                <td className="bgtr">{b.location}</td>
                                <td className="bgtr"><div className="button" onClick={()=>{
                                    this.props.navigate('/admin/bgs/edit',{state:{id:b._id}})
                                }}>edit</div></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <div className="button" style={{marginTop:15}}
                    onClick={()=>{
                        this.props.navigate('/admin/bgs/add')
                    }}
                >Add Barefoot Guardian</div>

            </div>
        )
    }
}

export default withNavigation(BGs)