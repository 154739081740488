import {
    SET_HEADER_TITLE,
    SLIDE_FORWARD,
    SLIDE_BACK,
    SET_SLIDE_INDEX
} from '../actions/types'

const initialState = {
    headerTitle : 'Welcome',
    slideIndex: 0
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_HEADER_TITLE:
            return {
                ...state,
                headerTitle:action.payload
            }
        case SET_SLIDE_INDEX:
            return {
                ...state,
                slideIndex:action.payload
            }
        case SLIDE_FORWARD:
            return {
                ...state,
                slideIndex:state.slideIndex += 1
            }
        case SLIDE_BACK:
            return {
                ...state,
                slideIndex:state.slideIndex -= 1
            }
        default:
            return state;
    }
}

