import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/buttons.css';
import logo from '../assets/logo_green.png';
import jani from '../assets/jani.jpg';
import {
    Heading,
    Text,
    Box,
    Flex
} from "@chakra-ui/react";
import { Button, ButtonGroup } from '@chakra-ui/react'
import {Link} from 'react-router-dom';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {

    }

    render() {
        return (
                    <div className="contentBox">
                        <img src={logo} style={{marginBottom:50}}/>
                        <Link to="/info">
                        <Box 
                        boxShadow='md' p='6' rounded='md' bg='white'

                        >
                            Tap to begin
                        </Box>
                        </Link>
                    </div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default connect(mapStateToProps,{})(Home)