import {
    SET_FEELING_SCORE,
    SET_NAME,
    SET_ARRIVED,
    SET_DEPARTING,
    SET_SYMPTOMS,
    SET_WELLNESS,
    SET_TREATMENTS,
    RESET_ONBOARDING,
    SET_EMAIL,
    SET_VILLA,
    SET_BG,
    SET_CURRENCY
} from './types';

    
export const setFeelingScore = (score) => {
    return {
        type:SET_FEELING_SCORE,
        payload:score
    }
}

export const setName = (name) => {
    return {
        type:SET_NAME,
        payload:name
    }
}

export const setEmail = (name) => {
    return {
        type:SET_EMAIL,
        payload:name
    }
}


export const setArrived = (date) => {
    return {
        type:SET_ARRIVED,
        payload:date
    }
}

export const setDeparting = (date) => {
    return {
        type:SET_DEPARTING,
        payload:date
    }
}
export const setVilla = (data) => {
    return {
        type:SET_VILLA,
        payload:data
    }
}

export const setBG = (data) => {
    return {
        type:SET_BG,
        payload:data
    }
}

export const setSymptoms = (sym) => {
    return {
        type:SET_SYMPTOMS,
        payload:sym
    }
}
export const setTreatments = (t) => {
    return {
        type:SET_TREATMENTS,
        payload:t
    }
}
export const setWellness = (well) => {
    return {
        type:SET_WELLNESS,
        payload:well
    }
}
export const setCurrency = (c) => {
    return {
        type:SET_CURRENCY,
        payload:c
    }
}
export const resetOnboarding = () => {
    return {
        type:RESET_ONBOARDING
    }
}