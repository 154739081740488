import React from 'react';
import './css/footer.css';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";


import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    FormHelperText,
    Flex,
    Center,
    Box,
    Progress
} from '@chakra-ui/react'




class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        console.log('footer props',this.props)
    }
    render() {
        return (
            <div className="footerwrap">
                <Progress colorScheme='green' size='sm' hasStripe value={this.props.nav.slideIndex / 4 * 100} sx={{flex:"1"}}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    nav:state.nav
})
export default connect(
    mapStateToProps,
    {}
)(Footer)