import React from 'react';
import { connect } from "react-redux";
import {
    addsendto,
    removesendto,
    updatesendto,
    updateTitle,
    updateMessage,
    updateSalutation
} from '../redux/actions/adminactions';

class Setup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email:''
        }
    }
    componentDidMount() {
    }
    render() {

        return (
            <div style={{
                padding:25
            }}>
                <div>setup</div>
                <table>
                {this.props.admin.sendto.map((s,i)=>{
                    return (
                        <tr>
                            <td style={{padding:10}}>{s}</td>
                            <td style={{padding:10}} onClick={()=>{this.props.removesendto(s)}}>remove</td>
                        </tr>
                    )
                })}
                </table>
                <div style={{width:300,background:'#efefef',display:'inline-block',padding:20,marginTop:10,borderRadius:4,flexDirection:'column',display:'inline-flex',justifyContent:'flex-start',alignItems:'flex-start'}}>
                    <div>add email:</div>
                    <input
                        style={{
                            width:'100%'
                        }} 
                        value={this.state.email}
                        onChange={(e)=>{
                            this.setState({
                                email:e.target.value
                            })
                        }}
                    />
                    <button style={{marginTop:10}}
                        onClick={()=>{
                            this.props.addsendto(this.state.email)
                        }}
                    >add</button>
                </div>
                <div style={{
                    background:'#efefef',
                    width:100,
                    marginTop:10,
                    padding:10,
                    textAlign:'center',
                    cursor:'pointer',
                    borderRadius:4
                }}
                onClick={()=>{
                    console.log('THE UPDATE PROPS',this.props.admin)
                    this.props.updatesendto(this.props.admin).then(res=>{
                        window.alert('list updated!')
                    }).catch(error=>{
                        window.alert('error updating')
                    })
                }}
                >save list</div>
                <div style={{
                    background:'#efefef',
                    width:500,
                    borderRadius:4,
                    marginTop:10,
                    padding:10
                }}>
                    <div style={{
                        marginBottom:10
                    }}>
                        <div>Title</div>
                        <input style={{width:'100%'}}
                        value={this.props.admin.title}
                        onChange={(e)=>{
                            this.props.updateTitle(e.target.value)
                        }}
                        />
                    </div>
                    <div style={{
                        marginBottom:10
                    }}>
                        <div>Message</div>
                        <input style={{width:'100%'}}
                        value={this.props.admin.message}
                        onChange={(e)=>{
                            this.props.updateMessage(e.target.value)
                        }}
                        />
                    </div>
                    <div style={{
                        marginBottom:10
                    }}>
                        <div>Salutation</div>
                        <input style={{width:'100%'}}
                        value={this.props.admin.salutation}
                        onChange={(e)=>{
                            this.props.updateSalutation(e.target.value)
                        }}
                        />
                    </div>

                </div>
            </div>
        )
    }    
}

const mapStateToProps = state => ({
    onboard:state.onboard,
    admin:state.admin
});
  
export default connect(mapStateToProps,{
    addsendto,
    removesendto,
    updatesendto,
    updateTitle,
    updateMessage,
    updateSalutation
})(Setup)
