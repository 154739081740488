import {
    SET_HEADER_TITLE,
    SLIDE_FORWARD,
    SLIDE_BACK,
    SET_SLIDE_INDEX
} from './types';

export const setHeaderTitle = (title) => {
    return {
        type:SET_HEADER_TITLE,
        payload:title
    }
}

export const setSlideIndex = (index) => {
    return {
        type:SET_SLIDE_INDEX,
        payload:index
    }
}

export const slideForward = () => {
    return {
        type:SLIDE_FORWARD
    }
}

export const slideBack = () => {
    return {
        type:SLIDE_BACK
    }
}