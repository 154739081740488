import {
    SET_FEELING_SCORE,
    SET_NAME,
    SET_ARRIVED,
    SET_DEPARTING,
    SET_SYMPTOMS,
    SET_WELLNESS,
    SET_TREATMENTS,
    RESET_ONBOARDING,
    SET_EMAIL,
    SET_VILLA,
    SET_BG,
    SET_CURRENCY
} from '../actions/types';

import moment from 'moment';
var later = new Date();
var more = later.setDate(later.getDate() + 7)
const initialSymptoms = [
    {
        q:"I am feeling sick (digestive, cold/flu, urinary tract infection, ear infection, cough, rash etc.)",
        s:false,
        t:"sick"
    },
    {
        q:"I have a chronic diagnosis (high blood pressure, cancer, autoimmune disease etc.)",
        s:false,
        t:"chronic"
    },
    {
        q:"I am tired",
        s:false,
        t:"tired"
    },
    {
        q:"I am feeling stressed / tense",
        s:false,
        t:"relax"
    },
    {
        q:"I have a headache",
        s:false,
        t:"tension"
    },
    {
        q:"I am feeling jetlagged",
        s:false,
        t:"jetlag"
    },
    {
        q:"I am experiencing pain in my body, muscles, bones",
        s:false,
        t:"pain"
    },
    {
        q:"I want to optimize my health",
        s:false,
        t:"optimize"
    },
    {
        q:"I need better sleep",
        s:false,
        t:"sleep"
    },
    {
        q:"I want to work on beauty / my skin",
        s:false,
        t:"beauty"
    },
    // {
    //     q:"I want to try something new",
    //     s:false
    // },
    {
        q:"I want to improve my fitness levels",
        s:false,
        t:"fitness"
    },
    {
        q:"I want to lose weight",
        s:false,
        t:"weight"
    },
    {
        q:"None of the above is true for me",
        s:false,
        t:"all_services"
    },


]

const initialState = {
    name: "",
    email:"",
    currency:"USD",
    feelingscore: 3,
    arrived: new Date(),
    departing:more,
    villa:'',
    bgs:[],
    symptoms : [
        {
            q:"I am feeling sick (digestive, cold/flu, urinary tract infection, ear infection, cough, rash etc.)",
            s:false,
            t:"sick"
        },
        {
            q:"I have a chronic diagnosis (high blood pressure, cancer, autoimmune disease etc.)",
            s:false,
            t:"chronic"
        },
        {
            q:"I am tired",
            s:false,
            t:"tired"
        },
        {
            q:"I am feeling stressed / tense",
            s:false,
            t:"relax"
        },
        {
            q:"I have a headache",
            s:false,
            t:"tension"
        },
        {
            q:"I am feeling jetlagged",
            s:false,
            t:"jetlag"
        },
        {
            q:"I am experiencing pain in my body, muscles, bones",
            s:false,
            t:"pain"
        },
        {
            q:"I want to optimize my health",
            s:false,
            t:"optimize"
        },
        {
            q:"I need better sleep",
            s:false,
            t:"sleep"
        },
        {
            q:"I want to work on beauty / my skin",
            s:false,
            t:"beauty"
        },
        // {
        //     q:"I want to try something new",
        //     s:false
        // },
        {
            q:"I want to improve my fitness levels",
            s:false,
            t:"fitness"
        },
        {
            q:"I want to lose weight",
            s:false,
            t:"weight"
        },
        {
            q:"None of the above is true for me",
            s:false,
            t:"all_services"
        },


    ],
    wellness : [
        {
            q:"Detox & Cleanse",
            s:false
        },
        {
            q:"Enhance Fitness",
            s:false
        },
        {
            q:"Manage Stress",
            s:false
        },
        {
            q:"Weight Management",
            s:false
        },
        {
            q:"Sleep Better",
            s:false
        },
        {
            q:"Cope with Emotions & Anxiety",
            s:false
        },
        {
            q:"Improve Posture",
            s:false
        },
        {
            q:"Improve Gut Health & Digestion",
            s:false
        },
        {
            q:"Improve Immunity",
            s:false
        },
        {
            q:"Build Strength & Flexibility",
            s:false
        },
        {
            q:"Just Relax",
            s:false
        },
        {
            q:"Group Retreats",
            s:false
        },

    ] 
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_NAME:
            return {
                ...state,
                name:action.payload
            }
        case SET_EMAIL:
            return {
                ...state,
                email:action.payload
            }
        case SET_CURRENCY:
            return {
                ...state,
                currency:action.payload
            }
        case SET_ARRIVED:
            return {
                ...state,
                arrived:action.payload
            }
        case SET_DEPARTING:
            return {
                ...state,
                departing:action.payload
            }
        case SET_FEELING_SCORE:
            return {
                ...state,
                feelingscore:action.payload
            }
        case SET_VILLA:
            return {
                ...state,
                villa:action.payload
            }
        case SET_BG:
            return {
                ...state,
                bgs:action.payload
            }
        case SET_SYMPTOMS:
            return {
                ...state,
                symptoms:action.payload
            }
        case SET_TREATMENTS:
            return {
                ...state,
                treatments:action.payload
            }
        case SET_WELLNESS:
            return {
                ...state,
                wellness:action.payload
            }
        case RESET_ONBOARDING:
            return {
                name: "Lilly-Marie Blecher",
                email:"drlillymarie@soneva.com",
                feelingscore: 3,
                arrived: new Date(),
                departing:more,
                bgs:[],
                villa:'',
                symptoms:initialSymptoms
            }
            break;
        default:
            return state;
    }
}