import React from 'react';
import { connect } from "react-redux";
import './css/screen.css';
import {
    setSymptoms
} from '../redux/actions/onboardactions';
import './css/home.css';
import './css/symptoms.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Symptoms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    updatesym = (sym) => {
        var newsym = [...this.props.onboard.symptoms];

        var i = newsym.findIndex(e=>e.q == sym.q);
        newsym[i].s = !newsym[i].s;
        this.props.setSymptoms(newsym)
    }
    render() {
        const {symptoms} = this.props.onboard;
        return (
                <div className="welcomeWrap"
                >
                    <div className="contentBox">
                        <div className="symwrap">
                            {symptoms.map(sym=>{
                                return (
                                    <div className="symbuttonoutline">
                                    <div className="symbutton" style={{background:sym.s ? 'lightskyblue' : '#dee0e7'}}
                                        onClick={()=>{
                                            this.updatesym(sym)
                                        }}
                                    >{sym.q}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = state => ({
    onboard:state.onboard
});
  
  export default connect(mapStateToProps,{
    setSymptoms
})(Symptoms)