import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import API from '../util/api';
import { SquareOutline } from 'react-ionicons';
import { CheckboxOutline} from 'react-ionicons';
import { imageUrl, avail } from '../config';



const  withParams = (Component : Component) => {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />;
  }
  
class BGEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bg:{},
            loading:true
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/bg/list/' + this.props.location.state.id
        }).then(res=>{
            this.setState({
                bg:res.data,
                loading:false
        })})

    }
    render() {
        console.log('BG PROPS',this.props)
        console.log('STATGE',this.state)
        return this.state.loading ? null : (
            <div style={{padding:15}}>
                <div className="bgwrap">
                    <div class="bgmaintitle">Edit</div>
                    <div className="bggroup">
                        <div className="bgtitle">
                            name
                        </div>
                        <div className="bginput">
                            <input 
                                value={this.state.bg.name}
                                onChange={(e)=>{
                                    this.setState({
                                        bg: {...this.state.bg,name:e.target.value}
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="bggroup">
                        <div className="bgtitle">
                            location
                        </div>
                        <div className="bginput">
                            <select value={this.state.bg.location}
                                onChange={(e)=>{
                                    this.setState({
                                        bg:{...this.state.bg,location:e.target.value}
                                    })
                                }}
                            >
                                {avail.map(a=>{
                                    return (
                                        <option value={a}>{a}</option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>
                    <div className="bggroup">
                        <div className="bgtitle">email</div>
                        <div className="bginput">
                            <input value={this.state.bg.email.email}
                                onChange={(e)=>{
                                    this.setState({
                                        bg:{...this.state.bg,email:{...this.state.bg.email,email:e.target.value}}
                                    })
                                }}
                            />
                        </div>
                        <div className="bginput">email active?
                                <div
                                    onClick={()=>{
                                        this.setState({
                                            bg:{...this.state.bg,email:{...this.state.bg.email,active:!this.state.bg.email.active}}
                                        })
                                    }}
                                >{this.state.bg.email.active ? <CheckboxOutline/> : <SquareOutline/>}</div>
                        </div>

                    </div>
                    <div className="bggroup">
                        <div className="bgtitle">whatsapp</div>
                        <div className="bginput">
                            <input value={this.state.bg.whatsapp.whatsapp}
                                onChange={(e)=>{
                                    this.setState({
                                        bg:{...this.state.bg,whatsapp:{...this.state.bg.whatsapp,whatsapp:e.target.value}}
                                    })
                                }}
                            />
                        </div>
                        <div className="bginput">whatsapp active?
                                <div
                                    onClick={()=>{
                                        this.setState({
                                            bg:{...this.state.bg,whatsapp:{...this.state.bg.whatsapp,active:!this.state.bg.whatsapp.active}}
                                        })
                                    }}
                                >{this.state.bg.whatsapp.active ? <CheckboxOutline/> : <SquareOutline/>}</div>
                        </div>

                    </div>
                    <div className="bggroup">
                        <div className="button"
                            onClick={()=>{
                                API.fetch({
                                    method:"POST",
                                    endpoint:"/bg/update/",
                                    data:{
                                        bg:this.state.bg
                                    }
                                }).then(res=>{
                                    console.log('the result is',res.data)
                                }).catch(error=>{
                                    console.log('ERROR!',error)
                                })
                            }}
                        >update</div>
                    </div>
                    <div className="bggroup">
                        <div className="button" style={{backgroundColor:'red',marginTop:10}}
                            onClick={()=>{
                                if(window.confirm('Delete this Barefoot Guardian?')) {
                                    API.fetch({
                                        method:"POST",
                                        endpoint:"/bg/delete",
                                        data: {
                                            id:this.state.bg._id
                                        }
                                    }).then(res=>{
                                        this.props.navigate(-1)
                                    }).catch(error=>{
                                        window.alert('Error deleting this Barefoot Guardian')
                                    })
    
                                } else {
                                    return;
                                }

                            }}
                        >delete</div>
                    </div>

                </div>
            <div style={{
                position:'fixed',
                bottom:10,
                left:10,
                backgroundColor:'#dedede',
                padding:10,
                cursor:'pointer'
            }}
                onClick={()=>{
                    this.props.navigate(-1)
                }}
            >back</div>
            </div>
        )
    }
}


export default withParams(BGEdit)