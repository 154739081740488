import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import API from '../util/api';
import { SquareOutline } from 'react-ionicons';
import { CheckboxOutline} from 'react-ionicons';



const  withParams = (Component : Component) => {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />;
  }
  
class BGAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bg:{
                email: {
                    email:".barefootguardian-jani@soneva.com",
                    active:true
                },
                whatsapp : {
                    whatsapp:"",
                    active:true
                },
                name:"",
                location:"Jani"
            },
            loading:false
        }
    }
    componentDidMount() {

    }
    render() {
        return this.state.loading ? null : (
            <div style={{padding:15}}>
                <div className="bgwrap">
                <div class="bgmaintitle">Create</div>
                    <div className="bggroup">
                        <div className="bgtitle">
                            name
                        </div>
                        <div className="bginput">
                            <input 
                                value={this.state.bg.name}
                                onChange={(e)=>{
                                    this.setState({
                                        bg: {...this.state.bg,name:e.target.value}
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="bggroup">
                        <div className="bgtitle">
                            location
                        </div>
                        <div className="bginput">
                            <select value={this.state.bg.location}
                                onChange={(e)=>{
                                    this.setState({
                                        bg:{...this.state.bg,location:e.target.value}
                                    })
                                }}
                            >
                                <option value="Jani">Jani</option>
                                <option value="Fushi">Fushi</option>

                            </select>
                        </div>
                    </div>
                    <div className="bggroup">
                        <div className="bgtitle">email</div>
                        <div className="bginput">
                            <input value={this.state.bg.email.email}
                                onChange={(e)=>{
                                    this.setState({
                                        bg:{...this.state.bg,email:{...this.state.bg.email,email:e.target.value}}
                                    })
                                }}
                            />
                        </div>
                        <div className="bginput">email active?
                                <div
                                    onClick={()=>{
                                        this.setState({
                                            bg:{...this.state.bg,email:{...this.state.bg.email,active:!this.state.bg.email.active}}
                                        })
                                    }}
                                >{this.state.bg.email.active ? <CheckboxOutline/> : <SquareOutline/>}</div>
                        </div>

                    </div>
                    <div className="bggroup">
                        <div className="bgtitle">whatsapp</div>
                        <div className="bginput">
                            <input value={this.state.bg.whatsapp.whatsapp}
                                onChange={(e)=>{
                                    this.setState({
                                        bg:{...this.state.bg,whatsapp:{...this.state.bg.whatsapp,whatsapp:e.target.value}}
                                    })
                                }}
                            />
                        </div>
                        <div className="bginput">whatsapp active?
                                <div
                                    onClick={()=>{
                                        this.setState({
                                            bg:{...this.state.bg,whatsapp:{...this.state.bg.whatsapp,active:!this.state.bg.whatsapp.active}}
                                        })
                                    }}
                                >{this.state.bg.whatsapp.active ? <CheckboxOutline/> : <SquareOutline/>}</div>
                        </div>

                    </div>
                    <div className="bggroup">
                        <div className="button"
                            onClick={()=>{
                                API.fetch({
                                    method:"POST",
                                    endpoint:"/bg/add/",
                                    data:{
                                        bg:this.state.bg
                                    }
                                }).then(res=>{
                                    console.log('the result is',res.data)
                                    this.props.navigate(-1)
                                }).catch(error=>{
                                    console.log('ERROR!',error)
                                })
                            }}
                        >Create Barefoot Guardian</div>
                    </div>

                </div>
            </div>
        )
    }
}


export default withParams(BGAdd)