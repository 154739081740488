import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';


const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 


class Admin extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div style={{padding:25,display:'flex',flexDirection:'column'}}>
                <h1 style={{fontSize:24,fontWeight:'bold',marginBottom:15}}>admin menu</h1>
                <div style={{cursor:'pointer',backgroundColor:'#efefef',padding:15,display:'inline-block',width:200,textAlign:'center',borderRadius:4,marginBottom:10}} onClick={()=>{
                    this.props.navigate('/admin/excel')
                }}>treatments</div>
                <div style={{cursor:'pointer',backgroundColor:'#efefef',padding:15,display:'inline-block',width:200,textAlign:'center',borderRadius:4,marginBottom:10}} onClick={()=>{
                    this.props.navigate('/admin/orders')
                }}>orders</div>
                <div style={{cursor:'pointer',backgroundColor:'#efefef',padding:15,display:'inline-block',width:200,textAlign:'center',borderRadius:4,marginBottom:10}} onClick={()=>{
                    this.props.navigate('/admin/bgs')
                }}>barefoot guardians</div>
                <div style={{cursor:'pointer',backgroundColor:'#efefef',padding:15,display:'inline-block',width:200,textAlign:'center',borderRadius:4,marginBottom:10}} onClick={()=>{
                    this.props.navigate('/admin/specialist')
                }}>specialists</div>
                <div style={{cursor:'pointer',backgroundColor:'#efefef',padding:15,display:'inline-block',width:200,textAlign:'center',borderRadius:4,marginBottom:10}} onClick={()=>{
                    this.props.navigate('/admin/setup')
                }}>basic setup</div>

            <h1 style={{fontSize:24,fontWeight:'bold',marginBottom:15,marginTop:20,background:'#efefef',display:'inline-block',padding:8,cursor:'pointer',display:'inline-block',width:300,textAlign:'center'}}
                onClick={()=>{
                    this.props.navigate('/')
                }}
            >back to main menu</h1>
            </div>
        )
    }
}

export default withNavigation(Admin)