import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} params={useParams()} />;
  } 

class AdminButton extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div style={{position:'fixed',right:10,bottom:10,backgroundColor:'#dedede',padding:10,cursor:'pointer'}}
                onClick={()=>{
                    this.props.navigate('/admin')
                }}
            >admin</div>

        )
    }
}

export default withNavigation(AdminButton)