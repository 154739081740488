import React from 'react';
import {connect} from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import Home from './swipescreens/home';
import HowAreYouFeeling from './swipescreens/howareyoufeeling';
import BasicInfo from './swipescreens/basicinfo';
import Symptoms from "./swipescreens/symptoms";
import Wellness from './swipescreens/wellness';
import './screens/css/screen.css';
import Header from './swipescreens/header';

import Order from './swipescreens/order_simple';

import Treatments from './admin/treatments';
import EditTreatment from './admin/edittreatment';
import NewTreatment from './admin/newtreatment';
import Admin from './admin/admin';
import { setSlideIndex } from './redux/actions/navactions';
import Footer from './swipescreens/footer';
import './swipescreens/swipe.css'
const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

class SwipeNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        
        const {slideIndex} = this.props.nav;
        return (

            <div style={{
                height:'100vh',
            }}>
                <div style={{
                    height:'100%',
                    display:'flex',
                    flexDirection:'column'
                }}>
             <Header/>

            <BindKeyboardSwipeableViews index={slideIndex}
                onChangeIndex={(e)=>{
                    this.props.setSlideIndex(e)
                }}
                style={{flex:1}}
            >
                <div className="swipewrap">
                    <Home/>
                </div>
                <div className="swipewrap">
                <BasicInfo/>
                </div>
                <div className="swipewrap">
                    <HowAreYouFeeling/>
                </div>
                <div style={{
                    width:'100%',
                    height:'100vh',
                    paddingTop:60,
                    paddingBottom:0
                }}>
                <Symptoms/>
                </div>
                {/* <div className="swipewrap">
                <Wellness/>
                </div> */}

                <div style={{
                    width:'100%',
                    height:'100vh',
                    paddingTop:60,
                    paddingBottom:150,
                }}>
                    <Order />
                </div>
            </BindKeyboardSwipeableViews>
            </div>
            </div>
            )
    }
}

const mapStateToProps = state => ({
    nav:state.nav
})

export default connect(
    mapStateToProps,
    {
        setSlideIndex
    }
)(SwipeNav)